import React from "react";

const Tag = ({ tag, onPress, noCross, onItemPress }) => {
  return (
    <div
      className="tagComponent"
      style={
        onItemPress && {
          cursor: "pointer",
        }
      }
      onClick={onItemPress ? onItemPress : null}
    >
      <p className="tagComponent__tag">
        {tag.substring(0, 8)} {tag.length > 10 && "..."}
      </p>
      {!noCross && (
        <p className="tagComponent__cancel" onClick={onPress.bind(this, tag)}>
          ❌
        </p>
      )}
    </div>
  );
};

export default Tag;
