import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { apiHeader, imageUrl, URL } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import EditSkillModal from "../components/AddAndEditCategoryModal";
import AddSectionModal from "../components/AddSectionModal";
// Mui Button
import MuiButton from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { toast } from "react-toastify";
import { navSection } from "../store/Actions/authAction";

const CategoriesScreen = () => {
  const dispatch = useDispatch();
  const router = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const token = useSelector((state) => state.authReducer.token);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  // loading states
  const [isGetting, setIsGetting] = useState(false);
  const [isAddingSection, setIsAddingSection] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCategory, setEditedCategory] = useState("");

  // Add section modal
  const [openAddSectionModal, setOpenAddSectionModal] = useState(false);
  const [addSectionName, setAddSectionName] = useState("");

  // data states
  const [sectionData, setSectionData] = useState([]);

  // get add sections
  const getAllSections = async () => {
    const url = URL("/admin/section");

    setIsGetting(true);
    const responseData = await Get(url, token);
    setIsGetting(false);

    if (responseData !== undefined) {
      setSectionData(responseData.data.data);
    }
  };

  useEffect(() => {
    getAllSections();
  }, [pageNumber]);

  // handleAddSection
  const handleAddSection = async (sectionName, position, image) => {
    const url = URL("/admin/section");
    // const params = { sectionName };
    const formData = new FormData();
    formData.append("photo", image);
    formData.append("sectionName", sectionName);
    formData.append("position", position);

    setIsAddingSection(true);
    const response = await Post(url, formData, apiHeader(token, true));
    setIsAddingSection(false);

    if (response != undefined) {
      setOpenAddSectionModal(false);
      toast.success("Section added successfully.");
      getAllSections();
    }
  };

  // handleSectionUpdate
  const handleSectionUpdate = async (_id, sectionName, position, image) => {
    const url = URL(`/admin/section/${_id}`);
    // const params = { sectionName };
    const formData = new FormData();
    formData.append("sectionName", sectionName);
    formData.append("position", position);
    formData.append("photo", image);

    setIsAddingSection(true);
    const response = await Patch(url, formData, apiHeader(token, true));
    setIsAddingSection(false);

    if (response != undefined) {
      setIsEditing(false);
      toast.success("Section edited successfully.");
      getAllSections();
    }
  };

  //  handleItemDelete
  const handleItemDelete = async (_id) => {
    const url = URL(`/admin/section/${deleteItemId}`);

    setIsAddingSection(true);
    const response = await Delete(url, null, apiHeader(token));
    setIsAddingSection(false);

    if (response !== undefined) {
      setIsDeleting(false);
      toast.success("Section deleted successfully.");
      getAllSections();
    }
  };

  // sectionNavigator
  const sectionNavigator = (_id) => {
    dispatch(navSection(_id));
    router.push("/chapters");
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Sections</h1>
      </div>

      {/* Add Section Button Container */}
      <div className="place-add-btn-container">
        <MuiButton
          onClick={() => setOpenAddSectionModal(true)}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Section
        </MuiButton>
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Sections</th>
                    <th>Position</th>
                    <th>Image</th>
                    <th>No. of Chapters</th>
                    <th>Last Updated</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sectionData?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>{`${item?.sectionName}`}</td>
                          <td>{`${item?.position}`}</td>
                          <td>
                            <img
                              width={"35px"}
                              height={"35px"}
                              src={`${imageUrl}${item?.photo}`}
                            />
                          </td>
                          <td>{item?.chapters.length}</td>
                          <td>
                            {moment(item.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td
                            style={{
                              display: "flex",
                            }}
                          >
                            <li
                              className="page-item align-self-end"
                              onClick={() => {
                                setEditedCategory({
                                  _id: item?._id,
                                  active: item?.active ? "active" : "inActive",
                                  sectionName: item?.sectionName,
                                  position: item?.position,
                                  photo: item?.photo,
                                });
                                setIsEditing(true);
                              }}
                            >
                              <Button className="pagination-button">
                                Edit
                              </Button>
                            </li>
                            <li
                              className="page-item align-self-end ml-5"
                              onClick={() => sectionNavigator(item?._id)}
                            >
                              <Button className="pagination-button">
                                See Chapters
                              </Button>
                            </li>
                            <li
                              className="page-item align-self-end ml-5"
                              onClick={() => {
                                setDeleteItemId(item?._id);
                                setIsDeleting(true);
                              }}
                            >
                              <Button className="pagination-button">
                                Delete
                              </Button>
                            </li>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              {/* <Pagination
                maxLastLength={maxLength}
                array={sectionData}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
              /> */}
            </div>
          </div>
        )}
      </div>

      {/* on Delete */}
      <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message="Do you really want to delete this section?"
        handleYes={handleItemDelete}
        isLoading={isAddingSection}
      />

      {/* EditSkillMiodal */}
      <EditSkillModal
        open={isEditing}
        setOpen={setIsEditing}
        _skill={editedCategory}
        onUpdate={handleSectionUpdate}
        isLoading={isAddingSection}
      />

      {/* AddSectionModal */}
      <AddSectionModal
        open={openAddSectionModal}
        setOpen={setOpenAddSectionModal}
        _skill={addSectionName}
        onUpdate={handleAddSection}
        isLoading={isAddingSection}
      />
    </section>
  );
};

export default CategoriesScreen;

const categoriesDummyData = [
  {
    _id: 1,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
];
