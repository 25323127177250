import React, { useEffect, useState } from "react";
import ModalSkeleton from "./ModalSkeleton";
import GroupWorkIcon from "@mui/icons-material/GroupWork";

// Radio imports
import { imageUrl } from "../Config/apiUrl";
import { green } from "@mui/material/colors";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
var colorRegEx = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

const AddAndEditAboutUsModal = ({
  open,
  setOpen,
  data,
  onUpdate,
  isLoading,
  faqModalKey,
  setData,
}) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [color, setColor] = useState("#000");
  const [status, setStatus] = useState("pending");

  const [modalKey, setModalKey] = useState(null);

  //   photo states
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  useEffect(() => {
    if (data) {
      setQuestion(data?.title);
      setAnswer(data?.text);
      setSelectedImage(data?.image);
    }

    return () => {
      setQuestion("");
      setAnswer("");
      setSelectedImage(null);
    };
  }, [faqModalKey, data]);

  // radio
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    console.log(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage(event.target.result);
      console.log("event.target.result: ", event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen} isSpecial={setData}>
      <form class="login100-form validate-form">
        <span class="login100-form-title">{!data ? "Add" : "Update"}</span>

        {/* question */}
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Title</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Input Title Here"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* question */}
        <div
          style={{ marginTop: "10px" }}
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Description</span>
          <ReactQuill
            theme="snow"
            class="input100"
            type="text"
            name="text"
            placeholder="Input Description Here"
            value={answer}
            onChange={setAnswer}
          />
        </div>

        {/* Image */}
        <div class="form-group" className="seo__imagePicker">
          <input
            type="file"
            name="uploadfile"
            id="image"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event);
            }}
          />
          <label
            for="exampleInputEmail1"
            className="label-input100"
            style={{ marginTop: "10px" }}
          >
            Select Image
          </label>
          <label
            for="image"
            className="btn btn-primary btn-lg btn-block"
            style={{
              backgroundColor: green[500],
            }}
            onChange={(e) => {
              console.log(e.target.files[0]);
            }}
          >
            {selectedImage ? "Change selection" : "Pick an Image"}
          </label>
        </div>

        {/* Shoe Image */}
        {selectedImage && (
          <div>
            <img
              src={
                selectedImageFile == null
                  ? `${imageUrl}${selectedImage}`
                  : selectedImage
              }
              className="faq-modal-image"
            />
          </div>
        )}

        {/* radio */}
        {/* {faqModalKey == "update" && (
          <FormControl component="fieldset" style={{ marginTop: "10px" }}>
            <RadioGroup
              row
              aria-label="status"
              name="controlled-radio-buttons-group"
              value={status}
              onChange={handleChange}
            >
              <FormControlLabel
                disabled
                value="pending"
                control={<Radio />}
                label="Pending"
              />
              <FormControlLabel
                value="rejected"
                control={<Radio />}
                label="Reject"
              />
              <FormControlLabel
                value="approved"
                control={<Radio />}
                label="Approve"
              />
            </RadioGroup>
          </FormControl>
        )} */}

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="login100-form-btn"
              onClick={async (e) => {
                e.preventDefault();
                if (question == "") {
                  return toast.error("Please input title");
                }
                if (answer == "") {
                  return toast.error("Please input description");
                }

                // create form data
                const formData = new FormData();
                formData.append("title", question);
                formData.append("text", answer);
                selectedImageFile !== null &&
                  formData.append("image", selectedImageFile);

                // if (modalKey == "add") {
                //   formData.append("status", "approved");
                // } else {
                //   formData.append("status", status);
                // }

                !data
                  ? await onUpdate(formData)
                  : await onUpdate(formData, data?._id);
                setQuestion("");
                setAnswer("");
                setSelectedImage(null);
                setSelectedImageFile(null);
              }}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditAboutUsModal;
