import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import {
  apiHeader,
  apiUrl,
  fallBackIcon,
  imageUrl,
  URL,
} from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import EditSkillModal from "../components/AddAndEditCategoryModal";
import AddSectionModal from "../components/AddSectionModal";
// Mui Button
import MuiButton from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddAndEditFaqModal from "./../components/AddAndEditFaqModal";
import { toast } from "react-toastify";
import NullDataComponent from "../components/NullDataComponent";

const FaqScreen = () => {
  const token = useSelector((state) => state.authReducer.token);
  const router = useHistory();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [faqs, setFaqs] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCategory, setEditedCategory] = useState("");

  //   faq modals
  const [isFaqModalOpen, setIsFaqModalOpen] = useState(false);
  const [faqModalKey, setFaqModalKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [faqModalData, setFaqModalData] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState(null);

  // reported ads listing method
  const getAllFaqs = async (pageNo, selectedLimit) => {
    setIsGetting(true);
    const url = URL(
      `/admin/get_all_Question_for_admin?page=${pageNo}&limit=${selectedLimit}`
    );
    const responseData = await Get(url, token);
    if (responseData !== undefined) {
      setFaqs(responseData?.data?.data);
    }
    setIsGetting(false);
  };

  useEffect(() => {
    getAllFaqs(page, limit);
  }, []);

  //   handleFaqUpdate
  const handleFaqUpdate = async (params, id) => {
    const url =
      faqModalKey == "add"
        ? URL(`/admin/add_faq_question`)
        : URL(`/admin/update_faq_question/${id}`);

    setIsLoading(true);
    const response =
      faqModalKey == "add"
        ? await Post(url, params, apiHeader(token, true))
        : await Patch(url, params, apiHeader(token, true));
    setIsLoading(false);

    if (response != undefined) {
      setIsFaqModalOpen(false);
      toast.success(
        faqModalKey == "add"
          ? "FAQ added successfully."
          : "FAQ edited successfully."
      );
      getAllFaqs(page, limit);
    }
  };

  //  handleItemDelete
  const handleItemDelete = async () => {
    const url = URL(`/admin/delete_add_faq_question/${idToBeDeleted}`);

    setIsLoading(true);
    const response = await Delete(url, null, apiHeader(token));
    setIsLoading(false);

    if (response !== undefined) {
      setIsOpenDeleteModal(false);
      toast.success("Question deleted successfully.");
      getAllFaqs(page, limit);
    }
  };

  const HandlePageChange = (value) => {
    const newPageNumber = page + value;
    setPage(newPageNumber);
    getAllFaqs(newPageNumber, limit);
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>FAQs</h1>
      </div>

      {/* Add Section Button Container */}
      <div className="place-add-btn-container">
        <MuiButton
          onClick={() => {
            setFaqModalKey("add");
            setIsFaqModalOpen(true);
          }}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
        >
          Add FAQ
        </MuiButton>
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Photo</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Last Updated</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {faqs?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={`${imageUrl}${item?.photo}`}
                              className="small-img"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = fallBackIcon;
                              }}
                            />
                          </td>
                          <td>{item?.question}</td>
                          <td>{item?.answer}</td>
                          <td>
                            {moment(item.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>{item?.status}</td>
                          <td
                            style={{
                              display: "flex",
                            }}
                          >
                            <li
                              className="page-item align-self-end"
                              onClick={() => {
                                setFaqModalData(item);
                                setFaqModalKey("update");
                                setIsFaqModalOpen(true);
                              }}
                            >
                              <Button className="pagination-button">
                                Edit
                              </Button>
                            </li>

                            <li
                              className="page-item align-self-end ml-5"
                              onClick={() => {
                                setIdToBeDeleted(item?._id);
                                setIsOpenDeleteModal(true);
                              }}
                            >
                              <Button className="pagination-button">
                                Delete
                              </Button>
                            </li>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              {faqs.length == 0 && <NullDataComponent text="No FAQs found." />}
            </div>
          </div>
        )}

        {isGetting == false && (
          <Pagination
            maxLastLength={limit}
            array={faqs}
            HandlePageChange={HandlePageChange}
            pageNumber={page}
          />
        )}
      </div>

      {/* to delete */}
      <AreYouSureModal
        open={isOpenDeleteModal}
        setOpen={setIsOpenDeleteModal}
        label={"Are You Sure?"}
        message="Do you really want to delete this question?"
        handleYes={handleItemDelete}
        isLoading={isLoading}
      />

      {/* AddAndEditFaqModal */}
      <AddAndEditFaqModal
        open={isFaqModalOpen}
        setOpen={setIsFaqModalOpen}
        data={faqModalData}
        setData={setFaqModalData}
        faqModalKey={faqModalKey}
        isLoading={isLoading}
        onUpdate={handleFaqUpdate}
      />
    </section>
  );
};

export default FaqScreen;

const categoriesDummyData = [
  {
    _id: 1,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
];
