import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Get, Post, Put } from "../Axios/AxiosFunctions";
import { apiHeader, apiUrl, imageUrl, URL as NODEURL } from "../Config/apiUrl";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import VideoCMS from "../components/CideoCMS";

function PageDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  //redux Value
  const accessToken = useSelector((state) => state.authReducer.token);
  const newpageDetails = useSelector(
    (state) => state.authReducer?.newpageDetails
  );

  console.log(newpageDetails);
  //api Header And Url
  const pageKeysApiURL = NODEURL(
    // `/cms/page/home`
    `/cms/page/${newpageDetails?.pageName}`
  );
  const submitApiURL = NODEURL(`/cms/page/update`);

  const Header = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // Page Edit State's
  const [pageKeysObject, setPageKeysObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [pageHeading, setPageHeading] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [newCoverImage, setNewCoverImage] = useState(null);
  const [displayImage, setDisplayImage] = useState("");
  const [newDisplayImage, setNewDisplayImage] = useState(null);

  const [section1Title, setSection1Title] = useState("");
  const [section1Description, setSection1Description] = useState("");
  const [section1Image, setSection1Image] = useState("");
  const [newSection1Image, setNewSection1Image] = useState(null);
  const [section1CoverImage, setSection1CoverImage] = useState("");
  const [newSection1CoverImage, setNewSection1CoverImage] = useState(null);

  const [section2Title, setSection2Title] = useState("");
  const [section2Description, setSection2Description] = useState("");
  const [section2Image, setSection2Image] = useState("");
  const [newSection2Image, setNewSection2Image] = useState(null);
  const [section2CoverImage, setSection2CoverImage] = useState("");
  const [newSection2CoverImage, setNewSection2CoverImage] = useState(null);

  const [section3Title, setSection3Title] = useState("");
  const [section3Description, setSection3Description] = useState("");
  const [section3Image, setSection3Image] = useState("");
  const [newSection3Image, setNewSection3Image] = useState(null);
  const [section3CoverImage, setSection3CoverImage] = useState("");
  const [newSection3CoverImage, setNewSection3CoverImage] = useState(null);

  // CMS VIdeo
  const [cmsVideoFile, setCmsVideoFile] = useState(null);
  const [cmsVideo, setCmsVideo] = useState(null);

  var formData = new FormData();

  const GetObjectKeysApiMethod = async () => {
    setLoading(true);
    const responseData = await Get(pageKeysApiURL, accessToken);
    if (responseData !== undefined) {
      setPageKeysObject(responseData?.data?.data);
      setPageHeading(responseData?.data?.data?.heading);
      setPageDescription(responseData?.data?.data?.detail_description);
      setDisplayImage(responseData?.data?.data?.display_image);
      setCoverImage(responseData?.data?.data?.cover_image);
      setCmsVideo(responseData?.data?.data?.display_video);
      {
        responseData?.data?.data?.sec1Heading !== undefined &&
          setSection1Title(responseData?.data?.data?.sec1Heading);
        setSection1Description(responseData?.data?.data?.sec1Description);
        setSection1Image(responseData?.data?.data?.sec1Image);
        setSection1CoverImage(responseData?.data?.data?.sec1CoverImage);
      }
      {
        responseData?.data?.data?.sec2Heading !== undefined &&
          setSection2Title(responseData?.data?.data?.sec2Heading);
        setSection2Description(responseData?.data?.data?.sec2Description);
        setSection2Image(responseData?.data?.data?.sec2Image);
        setSection2CoverImage(responseData?.data?.data?.sec2CoverImage);
      }
      {
        responseData?.data?.data?.sec3Heading !== undefined &&
          setSection3Title(responseData?.data?.data?.sec3Heading);
        setSection3Description(responseData?.data?.data?.sec3Description);
        setSection3Image(responseData?.data?.data?.sec3Image);
        setSection3CoverImage(responseData?.data?.data?.sec3CoverImage);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    GetObjectKeysApiMethod();
  }, []);

  const uploadImages = (event, setterFile, setterDisplay) => {
    if (event.target.files.length > 0) {
      setterFile(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (event) => {
        setterDisplay(event.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const createFormData = () => {
    // formData.append("_id", '61c5eabdec22cf23ac8c1218');
    // formData.append("pageName", 'home');

    formData.append("_id", newpageDetails?._id);
    formData.append("pageName", newpageDetails?.pageName);
    formData.append("heading", pageHeading);
    formData.append("detail_description", pageDescription);
    {
      newDisplayImage !== null
        ? formData.append("display_image", newDisplayImage)
        : formData.append("display_image", displayImage);
    }
    {
      newCoverImage !== null
        ? formData.append("cover_image", newCoverImage)
        : formData.append("cover_image", coverImage);
    }
    formData.append("display_video", cmsVideo);
    return formData;
  };

  const createSection1FormData = () => {
    formData.append("sec1Heading", section1Title);
    formData.append("sec1Description", section1Description);
    {
      newSection1Image !== null
        ? formData.append("sec1Image", newSection1Image)
        : formData.append("sec1Image", section1Image);
    }
    {
      newSection1CoverImage !== null
        ? formData.append("sec1CoverImage", newSection1CoverImage)
        : formData.append("sec1CoverImage", section1CoverImage);
    }
    return formData;
  };

  const createSection2FormData = () => {
    formData.append("sec2Heading", section2Title);
    formData.append("sec2Description", section2Description);
    {
      newSection2Image !== null
        ? formData.append("sec2Image", newSection2Image)
        : formData.append("sec2Image", section2Image);
    }
    {
      newSection2CoverImage !== null
        ? formData.append("sec2CoverImage", newSection2CoverImage)
        : formData.append("sec2CoverImage", section2CoverImage);
    }
    return formData;
  };

  const createSection3FormData = () => {
    formData.append("sec3Heading", section3Title);
    formData.append("sec3Description", section3Description);
    {
      newSection3Image !== null
        ? formData.append("sec3Image", newSection3Image)
        : formData.append("sec3Image", section3Image);
    }
    {
      newSection3CoverImage !== null
        ? formData.append("sec3CoverImage", newSection3CoverImage)
        : formData.append("sec3CoverImage", section3CoverImage);
    }
    return formData;
  };

  const submit = async () => {
    setSubmitLoading(true);

    let data = await createFormData();
    {
      pageKeysObject?.sec1Heading !== undefined &&
        (data = await createSection1FormData());
    }
    {
      pageKeysObject?.sec2Heading !== undefined &&
        (data = await createSection2FormData());
    }
    {
      pageKeysObject?.sec3Heading !== undefined &&
        (data = await createSection3FormData());
    }
    let responseData = await Post(submitApiURL, data, Header);

    if (responseData !== undefined) {
      console.log("response", responseData.data);
      toast.success(
        `${newpageDetails?.pageName} Page Content has been updated SuccessFully`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
    setSubmitLoading(false);
  };

  // handleVideoSubmit
  const handleVideoSubmit = async (selectedImageFile) => {
    const url = NODEURL(`/cms/page/upload-video/${pageKeysObject?._id}`);
    setSubmitLoading(true);
    const response1 = await Post(url, {}, apiHeader(accessToken));
    setSubmitLoading(false);

    if (!response1 !== undefined) {
      let _url = new URL(response1.data.data.url);
      // let key = _url.pathname.split("/")[1];

      setSubmitLoading(true);
      const r = await Put(_url, selectedImageFile);
      setSubmitLoading(false);

      if (r == undefined) {
        return toast.error(
          "Unable to upload your video. Please try again later."
        );
      }
      window.location.reload();
      toast.success("Video added successfully.");
    }
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Page Detail</h1>
      </div>
      <div className="container-fluid newMentorRequestDetails">
        <div
          className="row"
          style={{
            alignItems: "center",
          }}
        >
          {/* <div
            className="save-container"
            style={{
              width: "10%",
              marginRight: "1%",
              marginLeft: "2%",
            }}
          >
            <Button
              className="btn btn-user btn-block btn-save"
              onClick={() => {
                history.goBack();
              }}
              disabled={submitLoading}
            >
              back
            </Button>
          </div> */}
          <h1
            className="h3 mb-2 ml-3 mt-5 text-gray-800"
            style={{ textTransform: "uppercase" }}
          >
            {newpageDetails?.pageName} Page Details
          </h1>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {loading ? (
              <div className="row">
                <div className="col-md-12 darken-newMechanic on-loading">
                  <SpinnerRoundOutlined
                    enabled={loading}
                    thickness={200}
                    color="#175092"
                  />{" "}
                </div>
              </div>
            ) : (
              <>
                <div className="row Section-Container">
                  {newpageDetails.pageName === "home" && (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            for="InputHeading"
                            className="pages-label-style"
                          >
                            Heading
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="InputHeading"
                            placeholder="Enter Heading"
                            value={pageHeading}
                            onChange={(e) => setPageHeading(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            for="InputDescription"
                            className="pages-label-style"
                          >
                            Description
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="InputDescription"
                            placeholder="Enter Description"
                            value={pageDescription}
                            onChange={(e) => setPageDescription(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group Section-Image-Display">
                          <label
                            for="section1InputImage"
                            className="pages-label-style"
                          >
                            Display Image
                          </label>
                          <div
                            className="image-upload-avatar"
                            style={{
                              border:
                                newDisplayImage !== null || displayImage !== ""
                                  ? "0px solid #ced4da"
                                  : "1px solid #ced4da",
                            }}
                          >
                            {newDisplayImage !== null ? (
                              <img
                                src={displayImage}
                                alt="User Image"
                                className="image-fluid DisplayImageCms"
                              />
                            ) : displayImage !== "" ? (
                              <img
                                src={`${imageUrl}${displayImage}`}
                                alt="User Image"
                                className="image-fluid DisplayImageCms"
                              />
                            ) : (
                              <>
                                <span>
                                  <i
                                    className="fa fa-upload"
                                    style={{ fontSize: 50 }}
                                  />
                                  <p>Upload Photo</p>
                                </span>
                              </>
                            )}

                            <input
                              type="file"
                              id="section1InputImage"
                              accept="image/png, image/jpeg"
                              onChange={(e) =>
                                uploadImages(
                                  e,
                                  setNewDisplayImage,
                                  setDisplayImage
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-md-12">
                    <label for="InputCoverImage" className="pages-label-style">
                      Cover Image
                    </label>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group Image-Cover-Border">
                      <div className="image-upload-avatar">
                        {newCoverImage !== null ? (
                          <img
                            src={coverImage}
                            alt="User Image"
                            className="image-fluid CoverImageCms"
                          />
                        ) : coverImage !== "" ? (
                          <img
                            src={`${imageUrl}${coverImage}`}
                            alt="User Image"
                            className="image-fluid CoverImageCms"
                          />
                        ) : (
                          <>
                            <span>
                              <i
                                className="fa fa-upload"
                                style={{ fontSize: 50 }}
                              />
                              <p>Upload Photo</p>
                            </span>
                          </>
                        )}
                        <input
                          type="file"
                          id="InputCoverImage"
                          accept="image/png, image/jpeg"
                          onChange={(e) =>
                            uploadImages(e, setNewCoverImage, setCoverImage)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Section 1 Start */}
                {pageKeysObject?.sec1Heading !== undefined && (
                  <div className="row Section-Container">
                    <div className="col-md-12">
                      <h4 style={{ textTransform: "uppercase" }}>Section 1:</h4>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          for="section1InputHeading"
                          className="pages-label-style"
                        >
                          Heading
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="section1InputHeading"
                          placeholder="Enter Heading"
                          value={section1Title}
                          onChange={(e) => setSection1Title(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          for="section1InputDescription"
                          className="pages-label-style"
                        >
                          Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="section1InputDescription"
                          placeholder="Enter Description"
                          value={section1Description}
                          onChange={(e) =>
                            setSection1Description(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group Section-Image-Display">
                        <label
                          for="section1InputImage"
                          className="pages-label-style"
                        >
                          Image
                        </label>
                        <div
                          className="image-upload-avatar"
                          style={{
                            border:
                              newSection1Image !== null || section1Image !== ""
                                ? "0px solid #ced4da"
                                : "1px solid #ced4da",
                          }}
                        >
                          {newSection1Image !== null ? (
                            <img
                              src={section1Image}
                              alt="User Image"
                              className="image-fluid DisplayImageCms"
                            />
                          ) : section1Image !== "" ? (
                            <img
                              src={`${imageUrl}${section1Image}`}
                              alt="User Image"
                              className="image-fluid DisplayImageCms"
                            />
                          ) : (
                            <>
                              <span>
                                <i
                                  className="fa fa-upload"
                                  style={{ fontSize: 50 }}
                                />
                                <p>Upload Photo</p>
                              </span>
                            </>
                          )}

                          <input
                            type="file"
                            id="section1InputImage"
                            accept="image/png, image/jpeg"
                            onChange={(e) =>
                              uploadImages(
                                e,
                                setNewSection1Image,
                                setSection1Image
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label
                        for="InputCoverImage"
                        className="pages-label-style"
                      >
                        Cover Image
                      </label>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group Image-Cover-Border">
                        <div className="image-upload-avatar">
                          {newSection1CoverImage !== null ? (
                            <img
                              src={section1CoverImage}
                              alt="User Image"
                              className="image-fluid CoverImageCms"
                            />
                          ) : section1CoverImage !== "" &&
                            section1CoverImage !== undefined ? (
                            <img
                              src={`${imageUrl}${section1CoverImage}`}
                              alt="User Image"
                              className="image-fluid CoverImageCms"
                            />
                          ) : (
                            <>
                              <span>
                                <i
                                  className="fa fa-upload"
                                  style={{ fontSize: 50 }}
                                />
                                <p>Upload Photo</p>
                              </span>
                            </>
                          )}
                          <input
                            type="file"
                            id="InputCoverImage"
                            accept="image/png, image/jpeg"
                            onChange={(e) =>
                              uploadImages(
                                e,
                                setNewSection1CoverImage,
                                setSection1CoverImage
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Section 1 End */}

                {/* Section 2 start */}
                {pageKeysObject?.sec2Heading !== undefined && (
                  <div className="row Section-Container">
                    <div className="col-md-12">
                      <h4 style={{ textTransform: "uppercase" }}>Section 2:</h4>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          for="section1InputHeading"
                          className="pages-label-style"
                        >
                          Heading
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="section1InputHeading"
                          placeholder="Enter Heading"
                          value={section2Title}
                          onChange={(e) => setSection2Title(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          for="section1InputDescription"
                          className="pages-label-style"
                        >
                          Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="section1InputDescription"
                          placeholder="Enter Description"
                          value={section2Description}
                          onChange={(e) =>
                            setSection2Description(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group Section-Image-Display">
                        <label
                          for="section1InputImage"
                          className="pages-label-style"
                        >
                          Image
                        </label>
                        <div
                          className="image-upload-avatar"
                          style={{
                            border:
                              newSection2Image !== null || section2Image !== ""
                                ? "0px solid #ced4da"
                                : "1px solid #ced4da",
                          }}
                        >
                          {newSection2Image !== null ? (
                            <img
                              src={section2Image}
                              alt="User Image"
                              className="image-fluid DisplayImageCms"
                            />
                          ) : section2Image !== "" ? (
                            <img
                              src={`${imageUrl}${section2Image}`}
                              alt="User Image"
                              className="image-fluid DisplayImageCms"
                            />
                          ) : (
                            <>
                              <span>
                                <i
                                  className="fa fa-upload"
                                  style={{ fontSize: 50 }}
                                />
                                <p>Upload Photo</p>
                              </span>
                            </>
                          )}

                          <input
                            type="file"
                            id="section1InputImage"
                            accept="image/png, image/jpeg"
                            onChange={(e) =>
                              uploadImages(
                                e,
                                setNewSection2Image,
                                setSection2Image
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label
                        for="InputCoverImage"
                        className="pages-label-style"
                      >
                        Cover Image
                      </label>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group Image-Cover-Border">
                        <div className="image-upload-avatar">
                          {newSection2CoverImage !== null ? (
                            <img
                              src={section2CoverImage}
                              alt="User Image"
                              className="image-fluid CoverImageCms"
                            />
                          ) : section2CoverImage !== "" &&
                            section2CoverImage !== undefined ? (
                            <img
                              src={`${imageUrl}${section2CoverImage}`}
                              alt="User Image"
                              className="image-fluid CoverImageCms"
                            />
                          ) : (
                            <>
                              <span>
                                <i
                                  className="fa fa-upload"
                                  style={{ fontSize: 50 }}
                                />
                                <p>Upload Photo</p>
                              </span>
                            </>
                          )}
                          <input
                            type="file"
                            id="InputCoverImage"
                            accept="image/png, image/jpeg"
                            onChange={(e) =>
                              uploadImages(
                                e,
                                setNewSection2CoverImage,
                                setSection2CoverImage
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Section 2 End */}

                {/* Section 3 Start */}
                {pageKeysObject?.sec3Heading !== undefined && (
                  <div className="row Section-Container">
                    <div className="col-md-12">
                      <h4 style={{ textTransform: "uppercase" }}>Section 3:</h4>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label
                          for="section1InputHeading"
                          className="pages-label-style"
                        >
                          Heading
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="section1InputHeading"
                          placeholder="Enter Heading"
                          value={section3Title}
                          onChange={(e) => setSection3Title(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          for="section1InputDescription"
                          className="pages-label-style"
                        >
                          Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="section1InputDescription"
                          placeholder="Enter Description"
                          value={section3Description}
                          onChange={(e) =>
                            setSection3Description(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <div className="form-group Section-Image-Display">
                      <label
                        for="section1InputImage"
                        className="pages-label-style"
                      >
                        Image
                      </label>
                      <div
                        className="image-upload-avatar"
                        style={{
                          border:
                            newSection3Image !== null || section3Image !== ""
                              ? "0px solid #ced4da"
                              : "1px solid #ced4da",
                        }}
                      >
                        {newSection3Image !== null ? (
                          <img
                            src={section3Image}
                            alt="User Image"
                            className="image-fluid DisplayImageCms"
                          />
                        ) : section3Image !== "" ? (
                          <img
                            src={`${apiUrl}/${section3Image}`}
                            alt="User Image"
                            className="image-fluid DisplayImageCms"
                          />
                        ) : (
                          <>
                            <span>
                              <i
                                className="fa fa-upload"
                                style={{ fontSize: 50 }}
                              />
                              <p>Upload Photo</p>
                            </span>
                          </>
                        )}

                        <input
                          type="file"
                          id="section1InputImage"
                          accept="image/png, image/jpeg"
                          onChange={(e) =>
                            uploadImages(
                              e,
                              setNewSection3Image,
                              setSection3Image
                            )
                          }
                        />
                      </div>
                    </div> */}
                    </div>

                    <div className="col-md-12">
                      <label
                        for="InputCoverImage"
                        className="pages-label-style"
                      >
                        Cover Image
                      </label>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group Image-Cover-Border">
                        <div className="image-upload-avatar">
                          {newSection3CoverImage !== null ? (
                            <img
                              src={section3CoverImage}
                              alt="User Image"
                              className="image-fluid CoverImageCms"
                            />
                          ) : section3CoverImage !== "" &&
                            section3CoverImage !== undefined ? (
                            <img
                              src={`${imageUrl}${section3CoverImage}`}
                              alt="User Image"
                              className="image-fluid CoverImageCms"
                            />
                          ) : (
                            <>
                              <span>
                                <i
                                  className="fa fa-upload"
                                  style={{ fontSize: 50 }}
                                />
                                <p>Upload Photo</p>
                              </span>
                            </>
                          )}
                          <input
                            type="file"
                            id="InputCoverImage"
                            accept="image/png, image/jpeg"
                            onChange={(e) =>
                              uploadImages(
                                e,
                                setNewSection3CoverImage,
                                setSection3CoverImage
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {newpageDetails.pageName == "home" && (
                  <VideoCMS
                    cmsVideo={cmsVideo}
                    setCmsVideo={setCmsVideo}
                    cmsVideoFile={cmsVideoFile}
                    setCmsVideoFile={setCmsVideoFile}
                    onSumbit={handleVideoSubmit}
                    isApiCall={setSubmitLoading}
                  />
                )}
                {/* Section 3 End */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="save-container">
                      <Button
                        className="pagination-button"
                        style={{ backgroundColor: "#044d81 !important" }}
                        onClick={() => submitLoading == false && submit()}
                        disabled={submitLoading}
                      >
                        {submitLoading == true ? "Saving..." : "Save"}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageDetails;
