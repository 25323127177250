import React, { useEffect, useState } from "react";
import ModalSkeleton from "./ModalSkeleton";
import GroupWorkIcon from "@mui/icons-material/GroupWork";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { CardContent, CardMedia } from "@mui/material";
import { imageUrl } from "../Config/apiUrl";
import { green } from "@mui/material/colors";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

var colorRegEx = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

const AddAndEditVoucherModal = ({
  open,
  setOpen,
  data,
  onUpdate,
  isLoading,
  faqModalKey,
  setData,
}) => {
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [usageLimit, setUsageLimit] = useState("");
  const [status, setStatus] = useState("pending");

  const [modalKey, setModalKey] = useState(faqModalKey);

  //   photo states
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  //Dates States
  var endDatePlusOne = new Date();

  // add a day
  endDatePlusOne.setDate(endDatePlusOne.getDate() + 1);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(endDatePlusOne);

  useEffect(() => {
    if (faqModalKey == "update") {
      setCode(data?.code);
      setTitle(data?.title);
      //   setStatus(data?.status);
      setSelectedImage(data?.icon);
      setDiscount(data?.discount);
      setUsageLimit(data?.usageLimit);
    }
  }, [faqModalKey, data]);

  // radio
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    console.log(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage(event.target.result);
      console.log("event.target.result: ", event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen} isSpecial={setData}>
      <form class="login100-form validate-form">
        <span class="login100-form-title">
          {faqModalKey == "add" ? "Add" : "Update"}
        </span>

        {/* title */}
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Voucher Code</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Input Title Here"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* title */}
        <div
          style={{ marginTop: "10px" }}
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Description</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Input Description Here"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* discount */}
        <div
          style={{ marginTop: "10px" }}
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Discount</span>
          <input
            class="input100"
            type="number"
            name="text"
            min={1}
            max={100}
            placeholder="Input Discount"
            value={discount}
            onChange={(e) => {
              setDiscount(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* usageLimit */}
        <div
          style={{ marginTop: "10px" }}
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Usage Limit</span>
          <input
            class="input100"
            type="number"
            name="text"
            min={1}
            placeholder="Input Usage Limit"
            value={usageLimit}
            onChange={(e) => {
              setUsageLimit(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>
        <div className="d-flex">
          <div
            style={{
              marginRight: "20px",
            }}
          >
            <label>Start Date</label>
            <DatePicker
              selected={startDate}
              minDate={new Date()}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div>
            <label>End Date</label>

            <DatePicker
              minDate={endDatePlusOne}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </div>
        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="login100-form-btn"
              onClick={async (e) => {
                e.preventDefault();
                if (title == "") {
                  return toast.error("Please input title");
                }
                if (code == "") {
                  return toast.error("Please input code");
                }
                if (usageLimit == "" || usageLimit == 0) {
                  return toast.error("Uasge Limit must be greater than 0");
                }
                if (discount < 0 || discount > 100) {
                  return toast.error(
                    "Discount should be in range of 1 and 100"
                  );
                }

                const params = {
                  title,
                  code,
                  discount,
                  usageLimit,
                  voucher_start: startDate,
                  voucher_end: endDate,
                };

                faqModalKey == "add"
                  ? await onUpdate(params)
                  : await onUpdate(params, data?._id);
                setTitle("");
                setCode("");
                setSelectedImage(null);
                setSelectedImageFile(null);
              }}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditVoucherModal;
