import React, { useEffect, useState } from "react";
import ModalSkeleton from "./ModalSkeleton";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import CircularLoader from "./CircularLoader";

const AddChapterModal = ({
  open,
  setOpen,
  _skill,
  onUpdate,
  isLoading,
  seeds,
}) => {
  const [title, setTitle] = useState("");
  const [selectedPdfFiles, setSelectedPdfFiles] = useState(null);

  // handlePdfsSelect
  const handlePdfsSelect = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].type !== "application/pdf") {
        return toast.warn("Please upload only pdf file.");
      }
      setSelectedPdfFiles(e.target.files[0]);
    }
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form class="login100-form validate-form">
        <span class="login100-form-title">Add Resource</span>

        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Resource title</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Input resource title here"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        <div
          style={{
            marginTop: "20px",
          }}
        />
        {/* pdf */}
        <div class="form-group" className="seo__imagePicker">
          <input
            type="file"
            accept="application/pdf"
            name="uploadfile"
            id="pdfs"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handlePdfsSelect(event);
            }}
          />
          <label for="exampleInputEmail1">Select Pdfs</label>
          <label
            for="pdfs"
            className="btn btn-primary btn-lg btn-block"
            style={{
              backgroundColor: green[500],
            }}
          >
            {selectedPdfFiles ? `Change Selection` : "Pick Pdf"}
          </label>
        </div>

        {isLoading && (
          <div className="loader-circle">
            <CircularLoader val={seeds} />
          </div>
        )}

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                if (title == "" || selectedPdfFiles == null) {
                  return toast.warn("All fields are required.");
                }
                onUpdate(title, selectedPdfFiles);
                setSelectedPdfFiles(null);
                setTitle("");
              }}
            >
              {isLoading ? "Adding..." : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddChapterModal;
