import React from "react";

const NullDataComponent = ({ text, marginTop }) => {
  return (
    <div className="shadow-lg p-3 mb-5 rounded null-data-container" style={{marginTop: marginTop}}>
      <p style={{fontSize: '25px'}}>{text ? text : "No data found"}</p>
    </div>
  );
};

export default NullDataComponent;
