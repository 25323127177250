import React, { useEffect, useState } from "react";
import ModalSkeleton from "./ModalSkeleton";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import Tag from "./Tag";
import { green } from "@material-ui/core/colors";
import CardMedia from "@mui/material/CardMedia";
import { apiHeader, URL as NODE_URL } from "../Config/apiUrl";
import { Patch, Post, Put } from "../Axios/AxiosFunctions";
import { millisToMinutesAndSeconds } from "../Config/apiUrl";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import CircularLoader from "./CircularLoader";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";

const AddVideoModal = ({
  open,
  setOpen,
  onUpdate,
  editModalData,
  setEditModalData,
}) => {
  const chapterId = useSelector((state) => state.authReducer.chapterId);
  const [videoProgress, setVideoProgress] = useState(0);

  const token = useSelector((state) => state.authReducer.token);
  const [videoName, setVideoName] = useState("");
  const [VideoDescription, setVideoDescription] = useState("");
  const [videoSlug, setVideoSlug] = useState("");
  const [videoPreferences, setVideoPreferences] = useState("");
  const [videoTags, setVideoTags] = useState([]);
  const [videoTag, setVideoTag] = useState("");
  const [videoDuration, setVideoDuration] = useState(null);
  const [isApiCall, setIsApiCall] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const [selectedPdfFiles, setSelectedPdfFiles] = useState(null);

  useEffect(() => {
    if (editModalData) {
      setVideoName(editModalData?.name);
      setVideoDescription(editModalData?.description);
      setVideoPreferences(editModalData?.preferences);
      setVideoSlug(editModalData?.slug);
      setVideoTags(editModalData?.tags);
      setSelectedImage(editModalData?.link);
    } else {
      setVideoName("");
      setVideoDescription("");
      setVideoPreferences("");
      setVideoSlug("");
      setVideoTags([]);
      setSelectedImage(null);
      setSelectedImageFile(null);
    }
  }, [editModalData]);

  //  handleAddSeoTag
  const addVideoTags = (e) => {
    if (videoTag === "") {
      return;
    }

    setVideoTags((p) => [...p, videoTag]);
    setVideoTag("");
  };

  //   removeTagHandler
  const removeTagHandler = (tag) => {
    const tagsCopy = [...videoTags];
    const tagIndex = tagsCopy.findIndex((e) => e === videoTag);
    tagsCopy.splice(tagIndex, 1);
    setVideoTags(tagsCopy);
  };

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    let reader = new FileReader();

    reader.onload = (event) => {
      setSelectedImage(event.target.result);

      var media = new Audio(reader.result);
      media.onloadedmetadata = function () {
        setVideoDuration(millisToMinutesAndSeconds(media.duration * 1000));
      };
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  // handleVideoUpload
  const handleVideoUpload = async (e) => {
    e.preventDefault();

    const url = NODE_URL("/admin/video");
    const params = {
      name: videoName,
      description: VideoDescription,
      preferences: videoPreferences,
      slug: videoSlug,
      // tags: videoTags,
      chapter: chapterId,
      duration: videoDuration,
    };
    // validator
    for (const key in params) {
      if (params[key] == "" || params[key] == null) {
        return toast.error("All fields are required");
      }
    }

    if (videoTags.length == 0) {
      return toast.error("Please enter atleat 1 tag.");
    }

    const formData = new FormData();
    for (let key in params) {
      formData?.append(key, params[key]);
    }

    for (let key in videoTags) {
      formData?.append("tags[]", videoTags[key]);
    }

    if (selectedPdfFiles != null) {
      for (let i = 0; i < selectedPdfFiles.length - 1; i++) {
        formData.append("pdfs", selectedPdfFiles[i]);
      }
    }
    setIsApiCall(true);
    const response = await Post(url, formData, apiHeader(token, true));
    setIsApiCall(false);

    if (response !== undefined) {
      // clear fields
      setSelectedPdfFiles(null);
      setVideoDescription("");
      setVideoDuration(null);
      setVideoSlug("");
      setVideoPreferences("");
      setVideoTags([]);
      setSelectedImageFile(null);
      setSelectedImage(null);
      setVideoName("");
      try {
        let _url = new URL(response.data.data.url);
        let key = _url.pathname.split("/")[1];

        setIsApiCall(true);
        // const r = await Put(_url, selectedImageFile);
        axios
          .put(_url, selectedImageFile, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (data) => {
              console.log(Math.round((100 * data.loaded) / data.total));
              setVideoProgress(Math.round((100 * data.loaded) / data.total));
            },
          })
          .then(() => {
            setIsApiCall(false);
            toast.success("Video added successfully.");
            onUpdate();
          })
          .catch((e) => {
            console.log("e=> ", e);
          });

        // if (r == undefined) {
        //   return toast.error(
        //     "Unable to upload your video. Please try again later."
        //   );
        // }
      } catch (error) {
        console.log(error);
        setIsApiCall(false);
      }
    }
  };

  // handleVideoUpdate
  const handleVideoUpdate = async (e) => {
    e.preventDefault();
    const url = NODE_URL(`/admin/video/${editModalData?._id}`);
    const params = {
      name: videoName,
      description: VideoDescription,
      preferences: videoPreferences,
      slug: videoSlug,
      tags: videoTags,
      isVideoFileChange: selectedImageFile ? true : false,
      link: editModalData?.link,
    };

    setIsApiCall(true);
    const response = await Patch(url, params, apiHeader(token));
    setIsApiCall(false);

    if (response !== undefined) {
      if (!selectedImageFile) {
        setIsApiCall(false);

        toast.success("Video Details updated successfully.");
        return onUpdate();
      }
      try {
        let _url = new URL(response.data.data.url);
        let key = _url.pathname.split("/")[1];

        setIsApiCall(true);
        axios
          .put(_url, selectedImageFile, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (data) => {
              console.log(Math.round((100 * data.loaded) / data.total));
              setVideoProgress(Math.round((100 * data.loaded) / data.total));
            },
          })
          .then(() => {
            setIsApiCall(false);
            setSelectedImage(null);
            setSelectedImageFile(null);

            toast.success("Video Details updated successfully.");
            onUpdate();
          })
          .catch((e) => {
            console.log("e=> ", e);
          });
      } catch (error) {
        console.log(error);
        setIsApiCall(false);
      }
    }
  };

  // handlePdfsSelect
  const handlePdfsSelect = (e) => {
    console.log(e.target.files);
    setSelectedPdfFiles(e.target.files);
  };

  return (
    <ModalSkeleton
      open={open}
      setOpen={setOpen}
      type="big"
      isSpecial={setEditModalData}
    >
      <div class="login100-form validate-form new-big-modal">
        <span class="login100-form-title">
          {editModalData ? "Update Video" : "Add Video"}
        </span>

        {/* Video Name */}
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Video Title</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Enter Video Title Here"
            value={videoName}
            onChange={(e) => {
              setVideoName(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        <div style={{ marginBottom: "20px" }} />

        {/* Description */}
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Video Description</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Enter Video description"
            value={VideoDescription}
            onChange={(e) => {
              setVideoDescription(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>
        <div style={{ marginBottom: "20px" }} />

        {/* Overview */}
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Video Slug</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Enter Video slug"
            value={videoSlug}
            onChange={(e) => {
              setVideoSlug(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        <div style={{ marginBottom: "20px" }} />

        {/* Description */}
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100" for="videoPreferences">
            Video References
          </span>

          <ReactQuill
            id="videoPreferences"
            theme="snow"
            value={videoPreferences}
            onChange={setVideoPreferences}
          />
        </div>

        <div style={{ marginBottom: "20px" }} />

        {/* Tag */}
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Video Tag</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Enter Video Tag"
            value={videoTag}
            onChange={(e) => {
              setVideoTag(e.target.value);
            }}
            onKeyDown={(e) =>
              (e.code === "Enter" || e.code === "NumpadEnter") && addVideoTags()
            }
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>
        <div style={{ marginBottom: "20px" }} />

        {/* Map tags */}
        <div className="video__tags">
          {videoTags.map((tag) => (
            <Tag tag={tag} onPress={removeTagHandler} />
          ))}
        </div>

        <div
          style={{
            marginTop: "10px",
          }}
        />

        {/* Image input */}
        {/* {!editModalData && ( */}
        <div class="form-group" className="seo__imagePicker">
          <input
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            name="uploadfile"
            id="img"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event);
            }}
          />
          <label for="exampleInputEmail1">Select Video</label>
          <label
            for="img"
            className="btn btn-primary btn-lg btn-block"
            style={{
              backgroundColor: green[500],
            }}
            onChange={(e) => {
              console.log(e.target.files[0]);
            }}
          >
            {selectedImage ? "Change selection" : "Pick a Video"}
          </label>
        </div>
        {/* )} */}

        {/* Shoe Image */}
        {selectedImage && (
          <div>
            <CardMedia
              component="video"
              height="194"
              controls
              autoplay
              src={
                selectedImageFile == null ? `${selectedImage}` : selectedImage
              }
            />
          </div>
        )}

        {/* pdf */}
        {/* {!editModalData && (
          <div class="form-group" className="seo__imagePicker">
            <input
              type="file"
              accept="application/pdf"
              name="uploadfile"
              multiple
              id="pdfs"
              style={{
                display: "none",
              }}
              onChange={(event) => {
                handlePdfsSelect(event);
              }}
            />
            <label for="exampleInputEmail1">Select Pdfs</label>
            <label
              for="pdfs"
              className="btn btn-primary btn-lg btn-block"
              style={{
                backgroundColor: green[500],
              }}
            >
              {selectedPdfFiles
                ? `${selectedPdfFiles?.length} Files Selected`
                : "Pick Pdfs"}
            </label>
          </div>
        )} */}

        {isApiCall && (
          <div className="loader-circle">
            <CircularLoader val={videoProgress} />
          </div>
        )}

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isApiCall}
              class="login100-form-btn"
              onClick={editModalData ? handleVideoUpdate : handleVideoUpload}
            >
              {isApiCall ? "Please wait..." : editModalData ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default AddVideoModal;
