import React, { useEffect, useState } from "react";
import ModalSkeleton from "./ModalSkeleton";
import GroupWorkIcon from "@mui/icons-material/GroupWork";

const AddChapterModal = ({ open, setOpen, _skill, onUpdate, isLoading }) => {
  const [skill, setSkill] = useState("");
  const [description, setDescription] = useState("");

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form class="login100-form validate-form">
        <span class="login100-form-title">Add Chapter</span>

        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Chapter Name</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Input chapter Name Here"
            value={skill}
            onChange={(e) => {
              setSkill(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* description */}
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
          style={{marginTop: '10px'}}
        >
          <span class="label-input100">Chapter Description</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Input chapter Description Here"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                onUpdate(skill, description);
              }}
            >
              {isLoading ? "Adding..." : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddChapterModal;
