import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { URL } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import EditSkillModal from "../components/AddAndEditCategoryModal";
import AddSectionModal from "../components/AddSectionModal";
import * as authActions from "../store/Actions/authAction";
// Mui Button
import MuiButton from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const PagesScreen = () => {
  const dispatch = useDispatch();
  const router = useHistory();
  const accessToken = useSelector((state) => state.authReducer.token);
  const [loading, setLoading] = useState(false);
  const [isGetting, setIsGetting] = useState(false);
  const [pagesData, setPagesData] = useState([]);

  // reported ads listing method
  const getAllPages = async () => {
    setIsGetting(true);
    const url = URL(`/cms/pages?all=true`);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setPagesData(responseData?.data?.pages);
    }
    setIsGetting(false);
  };

  useEffect(() => {
    getAllPages();
  }, []);

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Pages</h1>
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Page Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {pagesData?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>{item?.pageName}</td>
                          <td
                            style={{
                              display: "flex",
                            }}
                          >
                            <li
                              className="page-item align-self-end"
                              onClick={() => {
                                dispatch(authActions.cmsPageUpdate(item));
                                router.push("/seo");
                              }}
                            >
                              <Button className="pagination-button">
                                Handle SEO
                              </Button>
                            </li>
                            <li
                              className="page-item align-self-end ml-5"
                              onClick={() => {
                                dispatch(authActions.cmsPageUpdate(item));
                                router.push("/detail");
                              }}
                            >
                              <Button className="pagination-button">
                                Change Page Content
                              </Button>
                            </li>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default PagesScreen;

const pages_d = [
  {
    _id: 1,
    name: "Home Page",
  },
];
