import { green } from "@mui/material/colors";
import React, { useState } from "react";
import CardMedia from "@mui/material/CardMedia";

const CideoCMS = ({
  cmsVideo,
  setCmsVideo,
  cmsVideoFile,
  setCmsVideoFile,
  onSumbit,
}) => {
  const [isApiCall, setIsApiCall] = useState(false);
  //   handleSelectImage
  const handleSelectImage = (event) => {
    setCmsVideoFile(event.target.files[0]);
    let reader = new FileReader();

    reader.onload = (event) => {
      setCmsVideo(event.target.result);

      var media = new Audio(reader.result);
      media.onloadedmetadata = function () {
        // setVideoDuration(millisToMinutesAndSeconds(media.duration * 1000));
      };
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <div className="row Section-Container">
      <div className="col-md-12">
        <h4 style={{ textTransform: "uppercase" }}>Page Video</h4>
      </div>

      {/* Image input */}
      <div className="test_b_main">
        <div className="test_b">
          <div class="form-group" className="seo__imagePicker">
            <input
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              name="uploadfile"
              id="img2121"
              style={{
                display: "none",
              }}
              onChange={(event) => {
                handleSelectImage(event);
              }}
            />
            <label
              for="img2121"
              className="btn btn-primary btn-lg btn-block"
              style={{
                backgroundColor: green[500],
              }}
              onChange={(e) => {
                console.log(e.target.files[0]);
              }}
            >
              {cmsVideo ? "Change selection" : "Pick a Video"}
            </label>
          </div>

          {/* Shoe Image */}
          {cmsVideo && (
            <div>
              <CardMedia
                component="video"
                height="200"
                controls
                autoplay
                src={cmsVideo}
              />
            </div>
          )}

          {cmsVideoFile && (
            <div class="container-login100-form-btn">
              <div class="wrap-login100-form-btn">
                <div class="login100-form-bgbtn"></div>
                <button
                  disabled={isApiCall}
                  class="login100-form-btn"
                  onClick={async () => {
                    setIsApiCall(true);
                    await onSumbit(cmsVideoFile);
                    setIsApiCall(false);
                  }}
                >
                  {isApiCall ? "Please wait..." : "Update"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CideoCMS;
