import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function UsersTypeSelectionSelect({ type, handleChange }) {
  return (
    <Box sx={{ minWidth: 200 }}>
      <FormControl fullWidth sx={{ width: 200 }}>
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          label="Type"
          onChange={handleChange}
        >
          <MenuItem value={"all"}>All Users</MenuItem>
          <MenuItem value={"activate"}>Active Users</MenuItem>
          <MenuItem value={"deactivate"}>Deactivated Users</MenuItem>
          <MenuItem value={"free-subscription"}>Free Trial Users</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
