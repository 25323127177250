import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import {
  apiHeader,
  apiUrl,
  fallBackIcon,
  imageUrl,
  URL,
} from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import EditSkillModal from "../components/AddAndEditCategoryModal";
import AddSectionModal from "../components/AddSectionModal";
// Mui Button
import MuiButton from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UpdateForumModal from "./../components/UpdateForumModal";
import { toast } from "react-toastify";
import { CardMedia } from "@material-ui/core";
import NullDataComponent from "../components/NullDataComponent";

const FaqScreen = () => {
  const token = useSelector((state) => state.authReducer.token);
  const router = useHistory();
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [faqs, setFaqs] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCategory, setEditedCategory] = useState("");

  //   faq modals
  const [isFaqModalOpen, setIsFaqModalOpen] = useState(false);
  const [faqModalKey, setFaqModalKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [faqModalData, setFaqModalData] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState(null);

  // reported ads listing method
  const getAllForums = async (pageNo, selectedLimit) => {
    setIsGetting(true);
    const url = URL(`/admin/forum?page=${pageNo}&limit=${selectedLimit}`);
    const responseData = await Get(url, token);
    if (responseData !== undefined) {
      setFaqs(responseData?.data?.data);
    }
    setIsGetting(false);
  };

  useEffect(() => {
    getAllForums(page, limit);
  }, []);

  //   handleFaqUpdate
  const handleFaqUpdate = async (params, id) => {
    const url = URL(`/admin/forum/${id}`);

    setIsLoading(true);
    const response = await Patch(url, params, apiHeader(token));
    setIsLoading(false);

    if (response != undefined) {
      setIsFaqModalOpen(false);
      toast.success("Forum Question edited successfully.");
      getAllForums(page, limit);
    }
  };

  //  handleItemDelete
  const handleItemDelete = async () => {
    const url = URL(`/admin/forum/${idToBeDeleted}`);

    setIsLoading(true);
    const response = await Delete(url, null, apiHeader(token));
    setIsLoading(false);

    if (response !== undefined) {
      setIsOpenDeleteModal(false);
      toast.success("Forum Question deleted successfully.");
      getAllForums(page, limit);
    }
  };

  const handleVideoMounted = (element, time) => {
    if (element !== null) {
      element.currentTime = time;
    }
  };

  const HandlePageChange = (value) => {
    const newPageNumber = page + value;
    setPage(newPageNumber);
    getAllForums(newPageNumber, limit);
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Forum</h1>
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Question</th>
                    <th className="sm-td">Answer</th>
                    <th>Last Updated</th>
                    <th>Status</th>
                    <th>At Duration</th>
                    <th>Video Title</th>
                    <td>Video</td>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {faqs?.map((item, index) => {
                    console.log(item?.video?.link);

                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>{item?.question}</td>
                          <td className="sm-td">
                            <p>{item?.answer}</p>
                          </td>
                          <td>
                            {moment(item.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>{item?.status}</td>
                          <td>
                            {parseFloat(item?.questionTime / 60).toFixed(2)} min
                            OR {item?.questionTime} sec
                          </td>
                          <td>{item?.video?.name}</td>
                          <td>
                            <CardMedia
                              ref={(e) =>
                                handleVideoMounted(e, item?.questionTime)
                              }
                              component="video"
                              height="194"
                              // width="200"
                              controls
                              autoplay
                              src={item?.video?.link}
                            />
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <li
                                className="page-item align-self-end"
                                onClick={() => {
                                  setFaqModalData(item);
                                  setFaqModalKey("update");
                                  setIsFaqModalOpen(true);
                                }}
                              >
                                <Button className="pagination-button">
                                  Edit
                                </Button>
                              </li>

                              <li
                                className="page-item align-self-end ml-5"
                                onClick={() => {
                                  setIdToBeDeleted(item?._id);
                                  setIsOpenDeleteModal(true);
                                }}
                              >
                                <Button className="pagination-button">
                                  Delete
                                </Button>
                              </li>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              {faqs?.length == 0 && (
                <NullDataComponent text={"No forum questions found. 😐"} />
              )}
            </div>
          </div>
        )}
        {isGetting == false && (
          <Pagination
            maxLastLength={limit}
            array={faqs}
            HandlePageChange={HandlePageChange}
            pageNumber={page}
          />
        )}
      </div>

      {/* to delete */}
      <AreYouSureModal
        open={isOpenDeleteModal}
        setOpen={setIsOpenDeleteModal}
        label={"Are You Sure?"}
        message="Do you really want to delete this question?"
        handleYes={handleItemDelete}
        isLoading={isLoading}
      />

      {/* UpdateForumModal */}
      <UpdateForumModal
        open={isFaqModalOpen}
        setOpen={setIsFaqModalOpen}
        data={faqModalData}
        setData={setFaqModalData}
        isLoading={isLoading}
        onUpdate={handleFaqUpdate}
      />
    </section>
  );
};

export default FaqScreen;

const categoriesDummyData = [
  {
    _id: 1,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
];
