import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch, Post } from "../Axios/AxiosFunctions";
import { apiUrl, URL } from "../Config/apiUrl";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import { setSelectedPage } from "../store/Actions/authAction";

function WebDashboardScreen() {
  const dispatch = useDispatch();
  const history = useHistory();

  //redux Value
  const { token: accessToken } = useSelector((state) => state.authReducer);
  const newpageDetails = useSelector(
    (state) => state.authReducer?.selectedPage
  );

  // Page Edit State's
  const [title, setTitle] = useState("web_dashboard");
  const [heading, setHeading] = useState("");
  const [content, setContent] = useState("");
  const [content2, setContent2] = useState("");
  const [pageData, setPageData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const CheckRequiredField = (obj) => {
    let requiredFields = false;
    Object.keys(obj).forEach(function (key) {
      if (obj[key] == "") {
        requiredFields = true;
      }
    });
    return requiredFields;
  };

  var formData = new FormData();

  const createFormData = () => {
    formData.append("_id", pageData?._id);
    formData.append("pageName", title);
    formData.append("heading", heading);
    formData.append("content_1", content);
    formData.append("content_2", content2);
    return formData;
  };

  const submit = async () => {
    let data = {
      title: title,
      heading: heading,
      content: `${content}`,
      content2: `${content2}`,
    };
    let requiredNull = CheckRequiredField(data);
    if (requiredNull == true) {
      toast.error("Enter Required Fields.");
      return null;
    }
    const newData = await createFormData();
    setSubmitLoading(true);
    let responseData = await Post(submitApiURL, newData, Header);

    if (responseData !== undefined) {
      toast.success(`${title} Content has been updated SuccessFully`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setSubmitLoading(false);
  };

  //api Header And Url
  const ApiURL = URL(`/cms/pages?page=web_dashboard&all=${false}`);

  const submitApiURL = URL(`/cms/page/update`);

  const Header = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // ==== getPageData ===
  const getPageData = async () => {
    setLoading(true);
    const responseData = await Get(ApiURL, undefined, true, dispatch);
    if (responseData !== undefined) {
      setPageData(responseData?.data?.data?.pages);
      setTitle(responseData?.data?.data?.pages?.pageName);
      setHeading(responseData?.data?.data?.pages?.heading);
      setContent(responseData?.data?.data?.pages?.content_1);
      setContent2(responseData?.data?.data?.pages?.content_2);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Edit</h1>
      </div>
      <div className="container-fluid newMentorRequestDetails">
        <div
          className="row"
          style={{
            alignItems: "center",
          }}
        >
          <h1
            className="h3 mb-2 text-gray-800"
            style={{ textTransform: "uppercase" }}
          >
            {title}
          </h1>
        </div>
        <div className="card mb-4">
          <div className="card-header py-3">
            <div className="col-md-12">
              <div className="form-group">
                <label for="InputHeading" className="pages-label-style">
                  Heading
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="InputHeading"
                  placeholder="Enter Heading..."
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label for="Section1" className="pages-label-style">
                  Seaction 1
                </label>
                <ReactQuill
                  id="Section1"
                  theme="snow"
                  value={content}
                  onChange={setContent}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label for="Section2" className="pages-label-style">
                  Seaction 2
                </label>

                <ReactQuill
                  id="Section2"
                  theme="snow"
                  value={content2}
                  onChange={setContent2}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="save-container">
                  <Button
                    className="btn btn-user btn-block btn-save"
                    onClick={() => submitLoading == false && submit()}
                    disabled={submitLoading}
                  >
                    {submitLoading == true ? "loading" : "Save"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WebDashboardScreen;
