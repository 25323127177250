import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { apiHeader, URL } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import UpdateChapterModal from "../components/UpdateChapterModal";
import NullDataComponent from "../components/NullDataComponent";
// Mui Button
import MuiButton from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddChapterModal from "../components/AddChapterModal";
import { toast } from "react-toastify";
import { navChapter } from "../store/Actions/authAction";

const ChaptersScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.authReducer.token);
  const sectionId = useSelector((state) => state.authReducer.sectionId);

  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);

  // Loading states
  const [isGetting, setIsGetting] = useState(false);
  const [isLoadingChapter, setIsLoadingChapter] = useState(false);
  const [isDeletingChapter, setIsDeletingChapter] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedChapter, setEditedChapter] = useState("");

  const [page, setPage] = useState(1);

  // Add section modal
  const [openChapterModal, setOpenChapterModal] = useState(false);

  // reported ads listing method
  const getAllChapters = async (_type = "initial") => {
    // const url = URL(`/admin/chapter?section_id=${sectionId}`);

    const url = URL(
      `/admin/chapter?section_id=${sectionId}&page=${
        _type == "initial" ? 1 : page
      }&limit=50`
    );

    setIsGetting(true);
    const responseData = await Get(url, token);
    setIsGetting(false);
    if (responseData !== undefined) {
      if (_type == "initial") {
        setPage(2);
        setChapters(responseData?.data?.data);
      } else {
        setPage((p) => p + 1);
        setChapters((p) => [...p, ...responseData?.data?.data]);
      }
      // let maxLastLength =
      //   pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      // setMaxLength(maxLastLength);
      // setChapters(responseData?.data?.data);
    }
  };

  useEffect(() => {
    getAllChapters();
  }, []);

  // handleAddChapter
  const handleAddChapter = async (chapterName, description) => {
    const url = URL("/admin/chapter");
    const params = { chapterName, section: sectionId, description };

    setIsLoadingChapter(true);
    const response = await Post(url, params, apiHeader(token));
    setIsLoadingChapter(false);

    if (response != undefined) {
      setOpenChapterModal(false);
      toast.success("Section added successfully.");
      getAllChapters();
    }
  };

  // handleChapterUpdate
  const handleChapterUpdate = async (sectionName, description, _id) => {
    const url = URL(`/admin/chapter/${_id}`);
    const params = { sectionName, description };

    setIsLoadingChapter(true);
    const response = await Patch(url, params, apiHeader(token));
    setIsLoadingChapter(false);

    if (response != undefined) {
      setIsEditing(false);
      toast.success("Section edited successfully.");
      getAllChapters();
    }
  };

  //  handleItemDelete
  const handleItemDelete = async (_id) => {
    const url = URL(`/admin/chapter/${deleteItemId}`);

    setIsDeletingChapter(true);
    const response = await Delete(url, null, apiHeader(token));
    setIsDeletingChapter(false);

    if (response !== undefined) {
      setIsDeleting(false);
      toast.success("Chapter deleted successfully.");
      getAllChapters();
    }
  };

  // handkleNavigator
  const handleNavigator = (_id, route = "videos") => {
    dispatch(navChapter(_id));
    history.push(`/${route}`);
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Chapters</h1>
      </div>

      {/* Add Section Button Container */}
      <div className="place-add-btn-container">
        <MuiButton
          onClick={() => setOpenChapterModal(true)}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Chapter
        </MuiButton>
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Chapter</th>
                    <th>Description</th>
                    <th>No. of Videos</th>
                    <th>No. of Resources</th>
                    <th>Last Updated</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {chapters?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>{`${item?.chapterName}`}</td>
                          <td>{`${item?.description}`}</td>
                          <td>{item?.videos?.length}</td>
                          <td>{item?.resources?.length}</td>
                          <td>
                            {moment(item.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              {/* Edit */}
                              <li
                                className="page-item align-self-end"
                                onClick={() => {
                                  setEditedChapter(item);
                                  setIsEditing(true);
                                }}
                              >
                                <Button className="pagination-button">
                                  Edit
                                </Button>
                              </li>
                              {/* See Videos */}
                              <li
                                className="page-item align-self-end ml-5"
                                onClick={() => {
                                  handleNavigator(item?._id);
                                }}
                              >
                                <Button className="pagination-button">
                                  See Videos
                                </Button>
                              </li>
                              {/* See Resources */}
                              {/* <li
                                className="page-item align-self-end ml-5"
                                onClick={() => {
                                  handleNavigator(item?._id, "resources");
                                }}
                              >
                                <Button className="pagination-button">
                                  See Resources
                                </Button>
                              </li> */}
                              {/* Delete */}
                              <li
                                className="page-item align-self-end ml-5"
                                onClick={() => {
                                  setDeleteItemId(item?._id);
                                  setIsDeleting(true);
                                }}
                              >
                                <Button className="pagination-button">
                                  Delete
                                </Button>
                              </li>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              <Button
                className="pagination-button"
                onClick={getAllChapters.bind(null, "not-initial")}
              >
                Load More
              </Button>

              {chapters.length == 0 && (
                <NullDataComponent text={"No chapter found."} />
              )}

              {/* {chapters.length > 0 && (
                <Pagination
                  maxLastLength={maxLength}
                  array={chapters}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              )} */}
            </div>
          </div>
        )}
      </div>

      {/* delete */}
      <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message="Do you really want to delete this chapter?"
        isLoading={isDeletingChapter}
        handleYes={handleItemDelete}
      />

      {/* EditSkillMiodal */}
      <UpdateChapterModal
        open={isEditing}
        setOpen={setIsEditing}
        _skill={editedChapter}
        onUpdate={handleChapterUpdate}
        isLoading={isLoadingChapter}
      />

      {/* AddChapterModal */}
      <AddChapterModal
        open={openChapterModal}
        setOpen={setOpenChapterModal}
        onUpdate={handleAddChapter}
        isLoading={isLoadingChapter}
      />
    </section>
  );
};

export default ChaptersScreen;

const chaptersDummydata = [
  {
    _id: 1,
    chapter: "Dummy Chapter",
    noOfVideos: 12,
    updatedAt: Date.now(),
    isActive: true,
  },
  {
    _id: 2,
    chapter: "Dummy Chapter",
    noOfVideos: 12,
    updatedAt: Date.now(),
    isActive: true,
  },
  {
    _id: 3,
    chapter: "Dummy Chapter",
    noOfVideos: 12,
    updatedAt: Date.now(),
    isActive: true,
  },
  {
    _id: 4,
    chapter: "Dummy Chapter",
    noOfVideos: 12,
    updatedAt: Date.now(),
    isActive: true,
  },
  {
    _id: 5,
    chapter: "Dummy Chapter",
    noOfVideos: 12,
    updatedAt: Date.now(),
    isActive: true,
  },
  {
    _id: 6,
    chapter: "Dummy Chapter",
    noOfVideos: 12,
    updatedAt: Date.now(),
    isActive: true,
  },
];
