import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { apiHeader, URL } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import EditSkillModal from "../components/AddAndEditCategoryModal";
import UserTypeSelectionSelect from "../components/UserTypeSelectionSelect";
import AddSectionModal from "../components/AddSectionModal";
// Mui Button
import MuiButton from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NullDataComponent from "../components/NullDataComponent";
import { toast } from "react-toastify";

const UsersScreen = () => {
  const router = useHistory();
  const accessToken = useSelector((state) => state.authReducer.token);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGetting, setIsGetting] = useState(false);

  const [deleteingUserId, setDeletingUserId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [page, setPage] = useState(1);
  const [type, setType] = useState("all");
  const [limit, setLimit] = useState(25);

  const [query, setQuery] = useState("");

  //   get all users

  const getAllUsers = async (pageNo, selectedLimit, status, q = "") => {
    const url = URL(
      `/admin/get_all_users?status=${status}&page=${pageNo}&limit=${selectedLimit}&q=${q}`
    );

    setIsGetting(true);
    const responseData = await Get(url, accessToken);
    setIsGetting(false);

    if (responseData !== undefined) {
      setUsers(responseData?.data?.data);
    }
  };

  useEffect(() => {
    getAllUsers(page, limit, type);
  }, [type]);

  //   handleUserDelete
  const handleUserDelete = async () => {
    if (deleteingUserId == null) {
      return;
    }

    const url = URL(`/admin/delete_user?id=${deleteingUserId}`);

    setLoading(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setLoading(false);

    if (response !== undefined) {
      toast.success("User deleted successfully.");
      await getAllUsers(page, limit, type);
    }
    setIsDeleting(false);
  };

  //   handle Activate De activate user
  const handleUserStatusChange = async (id, status) => {
    const url = URL(`/admin/activate_deactivate_user`);
    const params = {
      status,
      id,
    };

    setIsGetting(true);
    const response = await Post(url, params, apiHeader(accessToken));
    setIsGetting(false);

    if (response !== undefined) {
      toast.success("Status changed successfully");
      await getAllUsers(page, limit, type);
    }
  };
  const handleAllowSubs = async (item) => {
    const data = {
      allowed: !item?.subscriptionAssignedByadmin,
      user: item?._id,
    };
    const subsApi = URL("/users/update-subscriptions");
    setIsGetting(true);

    const response = await Patch(subsApi, data, apiHeader(accessToken));
    setIsGetting(false);
    if (response !== undefined) {
      toast.success("Subscription changed successfully");
      await getAllUsers(page, limit, type);
    }
  };

  const HandlePageChange = (value) => {
    const newPageNumber = page + value;
    setPage(newPageNumber);
    getAllUsers(newPageNumber, limit, type);
  };
  const HandleTypeChange = (event) => {
    setType(event.target.value);
    setPage(1);
    getAllUsers(1, limit, event.target.value);
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Users</h1>
      </div>

      {/* Add Video Button Container */}
      <div className="place-add-btn-container">
        <div className="bar-container">
          <input
            className="form-control search-bar"
            placeholder="Search..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button
            disabled={isGetting}
            className="pagination-button mr-2"
            onClick={() => {
              setQuery("");
              getAllUsers(1, limit, type, "");
            }}
          >
            Reset
          </Button>
          <Button
            disabled={isGetting}
            className="pagination-button"
            onClick={() => getAllUsers(1, limit, type, query)}
          >
            Search
          </Button>
        </div>
        <UserTypeSelectionSelect type={type} handleChange={HandleTypeChange} />
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr className="table-row-bold">
                    <th>S.NO</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Joined At</th>
                    <th>Last Login</th>
                    <th>Subscription Start</th>
                    <th>Subscription Expiring</th>
                    <th>Active</th>
                    <th>Subscription Assign By Admin</th>
                    <th>Subscription Status</th>
                    <th>Mobile App Taken</th>
                    <th>Mock Exam Taken</th>
                    <th>Mock up Length</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1 + (page - 1) * limit}</td>
                          <td>{item?.name}</td>
                          <td>{item?.email}</td>
                          <td>
                            {moment(item?.createdAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            {moment(item.lastLogin).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            {item?.subscriptionStartDate
                              ? moment(item.subscriptionStartDate).format(
                                  "ddd, DD MMM YYYY"
                                )
                              : "-"}
                          </td>
                          <td>
                            {item?.subscriptionEndDate
                              ? moment(item.subscriptionEndDate).format(
                                  "ddd, DD MMM YYYY"
                                )
                              : "-"}
                          </td>
                          <td>{!item?.deactivate ? "Yes" : "No"}</td>
                          <td>
                            {item?.subscriptionAssignedByadmin ? "Yes" : "No"}
                          </td>
                          <td>{!!item?.subscription ? "Yes" : "No"}</td>

                          <td>{!!item?.isMobileAppTaken ? "Yes" : "No"}</td>
                          <td>{!!item?.isMockExamTaken ? "Yes" : "No"}</td>
                          <td>{item?.mockupLength}</td>

                          <td
                            style={{
                              display: "flex",
                            }}
                          >
                            {/* active / Deactivate */}
                            <li
                              className="page-item align-self-end"
                              onClick={() =>
                                handleUserStatusChange(
                                  item?._id,
                                  item?.deactivate ? false : true
                                )
                              }
                            >
                              <Button className="pagination-button">
                                {!item?.deactivate ? "Deactivate" : "Activate"}
                              </Button>
                            </li>
                            {/* Delete */}
                            <li className="page-item align-self-end ml-5">
                              <Button
                                onClick={() => handleAllowSubs(item)}
                                className="pagination-button"
                              >
                                {item?.subscriptionAssignedByadmin ||
                                !!item?.subscription
                                  ? "Cancel Subscription"
                                  : "Allow Subscription"}
                              </Button>
                            </li>
                            <li
                              className="page-item align-self-end ml-5"
                              onClick={() => {
                                setDeletingUserId(item?._id);
                                setIsDeleting(true);
                              }}
                            >
                              <Button className="pagination-button">
                                Delete
                              </Button>
                            </li>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              {users?.length == 0 && (
                <NullDataComponent
                  text={"No user found."}
                  marginTop={"100px"}
                />
              )}
            </div>
          </div>
        )}
        {isGetting == false && (
          <Pagination
            maxLastLength={limit}
            array={users}
            HandlePageChange={HandlePageChange}
            pageNumber={page}
          />
        )}
      </div>

      {/* AreYouSureModal */}
      <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message="Do you really want to delete this user?"
        handleYes={handleUserDelete}
        isLoading={loading}
      />
    </section>
  );
};

export default UsersScreen;
