import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { URL, apiHeader, pdfUrl } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import NullDataComponent from "../components/NullDataComponent";
import AddResourceModal from "../components/AddResourceModal";
import EditSkillModal from "../components/AddAndEditCategoryModal";
import AddSectionModal from "../components/AddSectionModal";
import { toast } from "react-toastify";
import CircularLoader from "../components/CircularLoader";

// Mui Button
import MuiButton from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const ResourcesScreen = () => {
  // variables
  const token = useSelector((state) => state.authReducer.token);
  const videoId = useSelector((state) => state.authReducer.videoId);

  const header = apiHeader(token, true);
  console.log(header);

  const router = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGetting, setIsGetting] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);

  // reported ads listing method
  const getAllResources = async () => {
    setIsGetting(true);
    const url = URL(`/admin/video/get-resources/${videoId}`);
    // const url = URL(`/admin/chapter/get-resources/${videoId}`);

    const responseData = await Get(url, token);
    if (responseData !== undefined) {
      let maxLastLength =
        pageNumber === 1 ? pageNumber * limit : (pageNumber - 1) * limit;
      setMaxLength(maxLastLength);
      setResources(responseData?.data?.data?.resources);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  useEffect(() => {
    getAllResources();
  }, [pageNumber]);

  // handleAddResource
  const handleAddResource = async (title, resources) => {
    const url = URL(`/admin/video/upload/resources/${videoId}`);
    const params = { title, resources };

    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }

    setLoading(true);
    const response = await Post(url, formData, {
      headers: header.headers,
      onUploadProgress: (data) => {
        setVideoProgress(Math.round((100 * data.loaded) / data.total));
      },
    });
    setLoading(false);

    if (response != undefined) {
      setIsResourceModalOpen(false);
      toast.success("Resource added successfully.");
      getAllResources();
    }
  };

  const handleDeletePdf = async () => {
    const url = URL(`/admin/video/delete/resources/${videoId}`);

    setLoading(true);
    const response = await Post(
      url,
      {
        pdf: deleteId?.pdf,
      },
      apiHeader(token)
    );
    setLoading(false);

    if (response !== undefined) {
      toast.success("Resource deleted successfully.");
      setDeleteId(null);
      setIsDeleting(false);
      await getAllResources();
    }
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Resources</h1>
      </div>

      {/* Add Section Button Container */}
      <div className="place-add-btn-container">
        <MuiButton
          onClick={() => setIsResourceModalOpen(true)}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Resource
        </MuiButton>
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Title</th>
                    <th>Resource</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {resources?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>{item?.title}</td>
                          <td>
                            <p
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(`${pdfUrl}${item?.pdf}`, "_blank")
                              }
                            >
                              Click to Open
                            </p>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <li
                                className="page-item align-self-end ml-5"
                                onClick={() => {
                                  setDeleteId(item);
                                  setIsDeleting(true);
                                }}
                              >
                                <Button className="pagination-button">
                                  Delete
                                </Button>
                              </li>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              {resources?.length == 0 && (
                <NullDataComponent text={"No resource added yet."} />
              )}

              <Pagination
                maxLastLength={maxLength}
                array={resources}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
              />
            </div>
          </div>
        )}
      </div>

      {/* AreYouSureModal */}
      <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message="Do you really want to delete this resource?"
        handleYes={handleDeletePdf}
      />

      {/* AddResourceModal */}
      <AddResourceModal
        open={isAddResourceModalOpen}
        setOpen={setIsResourceModalOpen}
        onUpdate={handleAddResource}
        isLoading={loading}
        seeds={videoProgress}
      />
    </section>
  );
};

export default ResourcesScreen;

const categoriesDummyData = [
  {
    _id: 1,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
];
