import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red, green, purple, yellow } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RadarIcon from "@mui/icons-material/Radar";
import { Button } from "@material-ui/core";
import { Button as BootstrapBtn } from "react-bootstrap";
import moment from "moment";
import Tag from "./Tag";
import { pdfUrl } from "../Config/apiUrl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { navChapter, navVideo } from "../store/Actions/authAction";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function VideoCard({
  data,
  i,
  deletePermission,
  setEditModalData,
  setOpenAddVideoModal,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  i == 0 && console.log(data?._id);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 345 }} className="video-card">
      <CardHeader
        avatar={
          <Avatar
            sx={{
              bgcolor: "#" + ((Math.random() * 0xffffff) << 0).toString(16),
            }}
            aria-label="recipe"
          >
            {data?.name[0].toUpperCase()}
          </Avatar>
        }
        title={`${data?.name}`}
        subheader={moment(data?.updatedAt).format("LLL")}
      />
      <CardMedia
        component="video"
        height="194"
        controls
        autoplay
        src={data?.link}
      />
      <CardContent>
        <Typography
          variant="body2"
          color="text.secondary"
          className="text-to-3-lines"
        >
          {data?.description}
        </Typography>
      </CardContent>
      <CardActions>
        {/* Edit */}
        <BootstrapBtn
          className="btn btn-success"
          style={{ backgroundColor: green[500] }}
          onClick={() => {
            setEditModalData(data);
            setOpenAddVideoModal(true);
          }}
        >
          Edit
        </BootstrapBtn>
        <BootstrapBtn
          className="btn btn-success"
          style={{ backgroundColor: green[500] }}
          onClick={() => {
            dispatch(navVideo(data?._id));
            history.push(`/resources`);
          }}
        >
          see Resources
        </BootstrapBtn>
        {/* Delete */}
        <BootstrapBtn
          onClick={() => deletePermission(data?._id)}
          className="btn btn-success"
          style={{ backgroundColor: red[500] }}
        >
          Delete
        </BootstrapBtn>
        {/* Enable disable */}
        {/* <BootstrapBtn
          className="btn btn-success"
          style={{
            backgroundColor: data?.isActive ? purple[500] : yellow[500],
          }}
        >
          {data?.isActive ? "Disable" : "Enable"}
        </BootstrapBtn> */}

        {/* Expand functionality */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>Video Tags:</Typography>
          <Typography paragraph className="video__tags">
            {data?.tags.length > 0
              ? data.tags.map((e) => <Tag tag={e} noCross />)
              : "No Tags Added"}
          </Typography>
          {/* Pdf */}
          {/* <Typography>Pdfs:</Typography>
          <Typography paragraph className="video__tags">
            {data?.pdfs.length > 0
              ? data.pdfs.map((e, i) => (
                  <Tag
                    tag={`Pdf # ${++i}`}
                    onPress={() => {}}
                    onItemPress={() => window.open(`${pdfUrl}${e}`, "_blank")}
                  />
                ))
              : "No Pdf Added"}
          </Typography> */}
        </CardContent>
      </Collapse>
    </Card>
  );
}
