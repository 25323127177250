import {
  CMS_PAGE_UPDATE,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  NAV_CHAPTER,
  NAV_SECTION,
  SET_SELECTED_PAGE,
  NAV_VIDEO,
} from "../Actions/actionType";

const INITIAL_STATE = {
  token: "",
  isLogin: false,
  user: null,
  mode: "light",
  sectionId: null,
  chapterId: null,
  videoId: null,
  newpageDetails: null,
  selectedPage: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        user: action.payload?.data.user,
        isLogin: true,
        token: action.payload?.token,
      };
      break;

    case LOGOUT_REQUEST:
      return {
        ...state,
        token: "",
        isLogin: false,
        user_type: "",
        user: null,
      };

    case NAV_SECTION:
      return {
        ...state,
        sectionId: action.payload,
      };

    case NAV_VIDEO:
      return {
        ...state,
        videoId: action.payload,
      };

    case NAV_CHAPTER:
      return {
        ...state,
        chapterId: action.payload,
      };

    case CMS_PAGE_UPDATE:
      console.log(action.payload);
      return {
        ...state,
        newpageDetails: action.payload,
      };
    case SET_SELECTED_PAGE:
      return {
        ...state,
        selectedPage: action.data,
      };
    default:
      return state;
  }
};
