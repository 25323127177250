export const LOGIN_SUCCESS = "login-success";
export const LOGOUT_REQUEST = "logout-request";

export const NAV_SECTION = "NAV_SECTION";
export const NAV_CHAPTER = "NAV_CHAPTER";

export const CMS_PAGE_UPDATE = "CMS_PAGE_UPDATE";
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";

export const NAV_VIDEO = "NAV_VIDEO";
