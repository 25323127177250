import React, { useEffect, useState } from "react";
import ModalSkeleton from "./ModalSkeleton";
import GroupWorkIcon from "@mui/icons-material/GroupWork";

// Radio imports
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";

const UpdateChapterModal = ({ open, setOpen, _skill, onUpdate, isLoading }) => {
  const [skill, setSkill] = useState(_skill?.chapterName);
  const [description, setDescription] = useState(_skill?.description);

  // radio
  // const [value, setValue] = useState("active");
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  useEffect(() => {
    setSkill(_skill?.chapterName);
    setDescription(_skill?.description);
    // setValue(_skill?.active ? "active" : "inActive");
  }, [_skill]);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form class="login100-form validate-form">
        <span class="login100-form-title">Update Chapter</span>

        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Chapter</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Edit Chapter"
            value={skill}
            onChange={(e) => {
              setSkill(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
          style={{ marginTop: "10px" }}
        >
          <span class="label-input100">Description</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Edit description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* radio */}
        {/* <FormControl component="fieldset" style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="active"
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value="inActive"
              control={<Radio />}
              label="In-Active"
            />
          </RadioGroup>
        </FormControl> */}

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                onUpdate(skill, description, _skill?._id);
              }}
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default UpdateChapterModal;
