import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { apiHeader, imageUrl, URL } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import EditSocialLink from "../components/AddAndEditSocialModal";
import AddSocialModal from "../components/AddAndEditSocialModal";
// Mui Button
import MuiButton from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { toast } from "react-toastify";
import { navSection } from "../store/Actions/authAction";

const SocialScreen = () => {
  const dispatch = useDispatch();
  const router = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const token = useSelector((state) => state.authReducer.token);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  // loading states
  const [isGetting, setIsGetting] = useState(false);
  const [isAddingSocial, setIsAddingSocial] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCategory, setEditedCategory] = useState("");

  // Add section modal
  const [openSocialModal, setOpenSocialModal] = useState(false);

  // data states
  const [socialData, setSocialData] = useState([]);

  // get add sections
  const getAllSocial = async () => {
    const url = URL("/admin/social");

    setIsGetting(true);
    const responseData = await Get(url, token);
    setIsGetting(false);

    if (responseData !== undefined) {
      setSocialData(responseData.data.data);
    }
  };

  useEffect(() => {
    getAllSocial();
  }, [pageNumber]);

  // handleSocial
  const handleSocial = async (name, socialLink, image) => {
    const url = URL("/admin/social");
    // const params = { sectionName };
    const formData = new FormData();
    formData.append("name", name);
    formData.append("url", socialLink);
    // formData.append("icon", image);

    setIsAddingSocial(true);
    const response = await Post(url, formData, apiHeader(token, true));
    setIsAddingSocial(false);

    if (response != undefined) {
      setOpenSocialModal(false);
      toast.success("Header link added successfully.");
      getAllSocial();
    }
  };

  // handleSocialUpdate
  const handleSocialUpdate = async (name, socialLink, image, _id) => {
    const url = URL(`/admin/social/${_id}`);
    // const params = { sectionName };
    const formData = new FormData();
    formData.append("name", name);
    formData.append("url", socialLink);
    // formData.append("icon", image);

    setIsAddingSocial(true);
    const response = await Patch(url, formData, apiHeader(token, true));
    setIsAddingSocial(false);

    if (response != undefined) {
      setIsEditing(false);
      toast.success("Header link edited successfully.");
      getAllSocial();
    }
  };

  //  handleItemDelete
  const handleItemDelete = async (_id) => {
    const url = URL(`/admin/social/${deleteItemId}`);

    setIsAddingSocial(true);
    const response = await Delete(url, null, apiHeader(token));
    setIsAddingSocial(false);

    if (response !== undefined) {
      setIsDeleting(false);
      toast.success("Header link deleted successfully.");
      getAllSocial();
    }
  };

  // sectionNavigator
  const sectionNavigator = (_id) => {
    dispatch(navSection(_id));
    router.push("/chapters");
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Header Link</h1>
      </div>

      {/* Add Section Button Container */}
      <div className="place-add-btn-container">
        <MuiButton
          onClick={() => setOpenSocialModal(true)}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Link
        </MuiButton>
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Name</th>
                    <th>Url</th>
                    {/* <th>Icon</th> */}
                    <th>Last Updated</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {socialData?.map((item, index) => {
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>{`${item?.name}`}</td>
                          <td>{`${item?.url}`}</td>
                          {/* <td>
                            <img
                              width={"35px"}
                              height={"35px"}
                              src={`${imageUrl}${item?.icon}`}
                            />
                          </td> */}
                          <td>
                            {moment(item.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td
                            style={{
                              display: "flex",
                            }}
                          >
                            <li
                              className="page-item align-self-end"
                              onClick={() => {
                                setEditedCategory({
                                  _id: item?._id,
                                  name: item?.name,
                                  url: item?.url,
                                  icon: item?.icon,
                                });
                                setIsEditing(true);
                              }}
                            >
                              <Button className="pagination-button">
                                Edit
                              </Button>
                            </li>
                            <li
                              className="page-item align-self-end ml-5"
                              onClick={() => {
                                setDeleteItemId(item?._id);
                                setIsDeleting(true);
                              }}
                            >
                              <Button className="pagination-button">
                                Delete
                              </Button>
                            </li>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              {/* <Pagination
                maxLastLength={maxLength}
                array={socialData}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
              /> */}
            </div>
          </div>
        )}
      </div>

      {/* on Delete */}
      <AreYouSureModal
        open={isDeleting}
        setOpen={setIsDeleting}
        label={"Are You Sure?"}
        message="Do you really want to delete this header link?"
        handleYes={handleItemDelete}
        isLoading={isAddingSocial}
      />

      {isEditing && (
        <EditSocialLink
          open={isEditing}
          setOpen={setIsEditing}
          editData={editedCategory}
          edit={true}
          onUpdate={handleSocialUpdate}
          isLoading={isAddingSocial}
        />
      )}

      {openSocialModal && (
        <AddSocialModal
          open={openSocialModal}
          setOpen={setOpenSocialModal}
          onUpdate={handleSocial}
          isLoading={isAddingSocial}
        />
      )}
    </section>
  );
};

export default SocialScreen;

const categoriesDummyData = [
  {
    _id: 1,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
  {
    _id: 2,
    category: "Dummy Section",
    updatedAt: Date.now(),
    isActive: true,
    numOfChapters: 0,
  },
];
