import React, { useEffect, useState } from "react";
import ModalSkeleton from "./ModalSkeleton";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { green } from "@mui/material/colors";
import { imageUrl } from "../Config/apiUrl";
import { toast } from "react-toastify";

const AddSectionModal = ({ open, setOpen, _skill, onUpdate, isLoading }) => {
  const [skill, setSkill] = useState(_skill);
  const [position, setPosition] = useState(_skill);
  //   photo states
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    console.log(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage(event.target.result);
      console.log("event.target.result: ", event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form class="login100-form validate-form">
        <span class="login100-form-title">Add Section</span>

        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Section Name</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Input Section Name Here"
            value={skill}
            onChange={(e) => {
              setSkill(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Position</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Input Position Here"
            value={position}
            onChange={(e) => {
              setPosition(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* Image */}
        <div class="form-group" className="seo__imagePicker">
          <input
            type="file"
            name="uploadfile"
            id="image-22"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event);
            }}
          />
          <label
            for="exampleInputEmail1"
            className="label-input100"
            style={{ marginTop: "10px" }}
          >
            Select Image
          </label>
          <label
            for="image-22"
            className="btn btn-primary btn-lg btn-block"
            style={{
              backgroundColor: green[500],
            }}
            onChange={(e) => {
              console.log(e.target.files[0]);
            }}
          >
            {selectedImage ? "Change selection" : "Pick an Image"}
          </label>
        </div>

        {/* Shoe Image */}
        {selectedImage && (
          <div>
            <img
              src={
                selectedImageFile == null
                  ? `${imageUrl}${selectedImage}`
                  : selectedImage
              }
              className="faq-modal-image"
            />
          </div>
        )}

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                if (!skill || !selectedImageFile) {
                  return toast.warn("All fields are required.");
                }
                onUpdate(skill, position, selectedImageFile);
              }}
            >
              {isLoading ? "Adding..." : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddSectionModal;
