import "./App.css";
import "./assets/Styles/style.css";
import "./assets/Styles/sideBar.css";
import "./assets/Styles/loginScreen.css";
import "./assets/Styles/AddCategory.css";
import "./assets/Styles/AddAndEditCategoryModal.css";
import "./assets/Styles/Pagination.css";
import "./assets/Styles/AllFieldsets.css";
import "./assets/Styles/UpdateCityModal.css";
import "./assets/Styles/ReportedAds.css";
import "./assets/Styles/ViewAdDetails.css";
import "./assets/Styles/mainDashboard.css";
import "./assets/Styles/pageDetail.css";

import { Fragment } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import SideNavbar from "./components/SideNavbar";
import Login from "./Screens/Login";
import PageDetails from "./Screens/PageDetail";

function App() {
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  return (
    <>
      <ToastContainer />
      <Router>
        <Fragment>
          <div className="row">
            {isLogin ? <SideNavbar /> : <Login />}
          </div>
          {/* <PageDetails /> */}
        </Fragment>
      </Router>
    </>
  );
}

export default App;
