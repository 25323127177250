import React, { useEffect, useState } from "react";
import TableRowsLoader from "../components/TableRowsLoader";
import VideoCard from "../components/VideoCard";

// Mui Button
import MuiButton from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddVideoModal from "../components/AddVideoModal";
import { useSelector } from "react-redux";
import { apiHeader, URL } from "../Config/apiUrl";
import { Delete, Get } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import AreYouSureModal from "../components/AreYouSureModal";
import { Button } from "react-bootstrap";

const VideosScreen = () => {
  // variables
  const token = useSelector((state) => state.authReducer.token);
  const chapterId = useSelector((state) => state.authReducer.chapterId);

  // states
  const [openAddVideoModal, setOpenAddVideoModal] = useState(false);
  const [isGetting, setIsGetting] = useState(false);
  const [videosData, setVideosData] = useState([]);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [aboutToDeleteVideoId, setAboutToDeleteId] = useState("");
  const [isHittingModalAPI, setIsHittingModalAPI] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const [page, setPage] = useState(1);

  // ***************** get add videos ************
  const getAllVideos = async (_type = "initial") => {
    // const url = URL(`/admin/video?chapter_id=${chapterId}`);
    const url = URL(
      `/admin/video?chapter_id=${chapterId}&page=${
        _type == "initial" ? 1 : page
      }&limit=50`
    );

    setIsGetting(true);
    const response = await Get(url, token);
    setIsGetting(false);

    if (response !== undefined) {
      // setVideosData(response.data.data);
      if (_type == "initial") {
        setPage(2);
        setVideosData(response?.data?.data);
      } else {
        setPage((p) => p + 1);
        setVideosData((p) => [...p, ...response?.data?.data]);
      }
    }
  };

  useEffect(() => {
    getAllVideos();
  }, []);

  // *************** Delete popup and DELETE ************
  const deletePermission = (id) => {
    setAboutToDeleteId(id);
    setIsAreYouSureModalOpen(true);
  };

  const handleItemDelete = async () => {
    const url = URL(`/admin/video/${aboutToDeleteVideoId}`);

    setIsHittingModalAPI(true);
    const response = await Delete(url, null, apiHeader(token));
    setIsHittingModalAPI(false);

    if (response !== undefined) {
      setIsAreYouSureModalOpen(false);
      toast.success("Video deleted successfully.");
      getAllVideos();
    }
  };

  // ***************  handle Video Add Update *****************
  const handleVideoAddUpdate = async () => {
    setOpenAddVideoModal(false);
    await getAllVideos();
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Videos</h1>
      </div>

      {/* Add Video Button Container */}
      <div className="place-add-btn-container">
        <MuiButton
          onClick={() => {
            setEditModalData(null);
            setOpenAddVideoModal(true);
          }}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Video
        </MuiButton>
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="videos-card-container">
            {videosData?.map((e, i) => (
              <VideoCard
                data={e}
                key={i}
                i={i}
                deletePermission={deletePermission}
                setEditModalData={setEditModalData}
                setOpenAddVideoModal={setOpenAddVideoModal}
              />
            ))}
          </div>
        )}

        {videosData.length > 50 && (
          <Button
            className="pagination-button"
            onClick={getAllVideos.bind(null, "not-initial")}
          >
            Load More
          </Button>
        )}
      </div>

      {/* AddVideoModal */}
      <AddVideoModal
        open={openAddVideoModal}
        setOpen={setOpenAddVideoModal}
        onUpdate={handleVideoAddUpdate}
        editModalData={editModalData}
        setEditModalData={setEditModalData}
      />

      {/* on Delete */}
      <AreYouSureModal
        open={isAreYouSureModalOpen}
        setOpen={setIsAreYouSureModalOpen}
        label={"Are You Sure?"}
        message="Do you really want to delete this video?"
        handleYes={handleItemDelete}
        isLoading={isHittingModalAPI}
      />
    </section>
  );
};

export default VideosScreen;

const videosData_d = [
  {
    _id: 1,
    isActive: true,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },

  {
    _id: 1,
    isActive: false,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
  {
    _id: 1,
    isActive: true,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
  {
    _id: 1,
    isActive: true,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
  {
    _id: 1,
    isActive: false,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
  {
    _id: 1,
    isActive: true,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
  {
    _id: 1,
    isActive: true,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
  {
    _id: 1,
    isActive: false,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
  {
    _id: 1,
    isActive: true,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
  {
    _id: 1,
    isActive: true,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
  {
    _id: 1,
    isActive: true,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
  {
    _id: 1,
    isActive: true,
    name: "Dummy Video",
    updatedAt: Date.now(),
    url: "https://duago.cc/DuaGo.mp4",
    description: `Lorem Ipsum is simply dummy text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text ever
    since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book.`,
    overview: `Lorem Ipsum is simply dummy <br /> text of the printing and typesetting
    industry. Lorem Ipsum has been the industry's standard dummy text
    ever since the 1500s, when an unknown printer took a galley of type
    and scrambled it to make a type specimen book. It has survived not
    only five centuries, but also the leap into electronic typesetting,
    remaining essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum passages,
    and more recently with desktop publishing software like Aldus
    PageMaker including versions of Lorem Ipsum.`,
  },
];
