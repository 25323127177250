import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Get, Patch, Post } from "../Axios/AxiosFunctions";
import { apiUrl, URL } from "../Config/apiUrl";
import { SpinnerRoundOutlined } from "spinners-react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import { setSelectedPage } from "../store/Actions/authAction";

function ContentEditor() {
  const dispatch = useDispatch();
  const history = useHistory();

  //redux Value
  const { token: accessToken } = useSelector((state) => state.authReducer);
  const newpageDetails = useSelector(
    (state) => state.authReducer?.selectedPage
  );
  //api Header And Url

  const submitApiURL = URL(
    `/cms/${newpageDetails?.linkPath}/${newpageDetails?._id}`
  );

  const Header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // Page Edit State's
  const [title, setTitle] = useState(newpageDetails?.title);
  const [content, setContent] = useState(newpageDetails?.content);
  const [submitLoading, setSubmitLoading] = useState(false);

  const CheckRequiredField = (obj) => {
    let requiredFields = false;
    Object.keys(obj).forEach(function (key) {
      if (obj[key] == "") {
        requiredFields = true;
      }
    });
    return requiredFields;
  };

  const submit = async () => {
    let data = {
      title: title,
      content: `${content}`,
    };
    let requiredNull = CheckRequiredField(data);
    if (requiredNull == true) {
      toast.error("Enter Required Fields.");
      return null;
    }
    setSubmitLoading(true);
    let responseData = await Patch(submitApiURL, data, Header);

    if (responseData !== undefined) {
      toast.success(`${title} Content has been updated SuccessFully`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setSubmitLoading(false);
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Edit</h1>
      </div>
      <div className="container-fluid newMentorRequestDetails">
        <div
          className="row"
          style={{
            alignItems: "center",
          }}
        >
          <div
            className="save-container"
            style={{
              width: "10%",
              marginRight: "1%",
              marginLeft: "2%",
            }}
          >
            <Button
              className="btn btn-user btn-block btn-save"
              onClick={() => {
                dispatch(setSelectedPage(null));
                history.goBack();
              }}
              disabled={submitLoading}
            >
              back
            </Button>
          </div>
          <h1
            className="h3 mb-2 text-gray-800"
            style={{ textTransform: "uppercase" }}
          >
            {title}
          </h1>
        </div>
        {/* <h1
        className="h3 mb-2 text-gray-800"
        style={{ textTransform: "capitalize" }}
      >
        {title}
      </h1> */}
        <div className="card mb-4">
          <div className="card-header py-3">
            {/* <div className="row Section-Container">
            <div className="col-md-12">
              <ReactQuill theme="snow" value={content} onChange={setContent} />
            </div>
          </div> */}
            <div className="col-md-12">
              <ReactQuill theme="snow" value={content} onChange={setContent} />
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="save-container">
                  <Button
                    className="btn btn-user btn-block btn-save"
                    onClick={() => submitLoading == false && submit()}
                    disabled={submitLoading}
                  >
                    {submitLoading == true ? "loading" : "Save"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContentEditor;
