import React, { useState, useEffect } from "react";
import { loginBg, underProducation } from "../constant/imagePath";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useDispatch } from "react-redux";

import { Post } from "../Axios/AxiosFunctions";
import { URL, validateEmail, apiHeader } from "../Config/apiUrl";
import { toast } from "react-toastify";
import { saveLoginUserData } from "../store/Actions/authAction";

const Login = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState("");

  const handleChange = (e, setter) => {
    const { id, value } = e.target;
    setter(value);
  };

  // loginHandler
  const loginHandler = async (e) => {
    e.preventDefault();
    const url = URL("/users/admin-login");
    const params = { email, password, role: "admin" };

    for (const key in params) {
      if (params[key].trim() === "") {
        return toast.error(`${key} is required.`);
      }
    }

    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email address.");
    }

    setIsLoading(true);
    const response = await Post(url, params, apiHeader());
    setIsLoading(false);

    if (response !== undefined) {
      dispatch(saveLoginUserData(response.data));
    }
  };

  return (
    <div class="limiter">
      <div
        class="container-login100"
        style={{ backgroundImage: `url(${loginBg})` }}
      >
        <div class="wrap-login100">
          <form class="login100-form validate-form">
            <span class="login100-form-title"> Login </span>

            <div
              class="wrap-input100 validate-input"
              data-validate="Username is reauired"
            >
              <span class="label-input100">Username</span>
              <input
                class="input100"
                type="text"
                name="username"
                placeholder="Type your username"
                value={email}
                onChange={(e) => {
                  handleChange(e, setEmail);
                }}
              />
              <span class="focus-input100">
                <PersonOutlineOutlinedIcon />
              </span>
            </div>

            <div
              class="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <span class="label-input100">Password</span>
              <input
                class="input100"
                type="password"
                name="pass"
                placeholder="Type your password"
                value={password}
                onChange={(e) => {
                  handleChange(e, setPassword);
                }}
              />
              <span class="focus-input100">
                <LockOutlinedIcon />
              </span>
            </div>

            <div class="container-login100-form-btn">
              <div class="wrap-login100-form-btn">
                <div class="login100-form-bgbtn"></div>
                <button
                  class="login100-form-btn"
                  disabled={isLoading}
                  onClick={(e) => loginHandler(e)}
                >
                  {isLoading ? "Logging in..." : "Login"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
