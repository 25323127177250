import React from "react";

function Pagination({ maxLastLength, array, HandlePageChange, pageNumber }) {
  return (
    <nav aria-label="Page navigation example" className="paginationContainer">
      <ul className="pagination">
        <li
          onClick={() => {
            pageNumber > 1 && HandlePageChange(-1);
          }}
          className={`previous ${pageNumber == 1 && "disabled"}`}
        >
          <button
            className={`btn pagination-button ${
              pageNumber == 1 && "disabled-btn"
            }`}
          >
            &laquo; Previous
          </button>
        </li>

        <li className="middle">{pageNumber}</li>
        <li
          className={`next ${maxLastLength > array?.length && "disabled"}`}
          onClick={() => {
            maxLastLength == array?.length && HandlePageChange(1);
          }}
        >
          <button
            className={`btn pagination-button ${
              maxLastLength > array?.length && "disabled-btn"
            }`}
          >
            Next &raquo;
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
