import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { apiUrl, imageUrl, URL } from "../Config/apiUrl";
// import { setSelectedPage } from "../store/actions/AllActions";
import { Button } from "react-bootstrap";
import Tag from "../components/Tag";
import { Get, Post } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";

const Seo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  //redux Value
  const accessToken = useSelector((state) => state.authReducer.token);
  const newpageDetails = useSelector(
    (state) => state.authReducer?.newpageDetails
  );
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const getUrl = URL(`/cms/seo/${newpageDetails?.pageName}`);

  useEffect(() => {
    getData();
    // return () => dispatch(setSelectedPage(null));
  }, []);

  //   getData
  const getData = async () => {
    setSubmitLoading(true);
    const resData = await Get(getUrl, accessToken);
    setSubmitLoading(false);
    if (resData !== undefined) {
      if (resData.data.data !== null) {
        setTitle(resData?.data?.data?.title);
        setDescription(resData?.data?.data?.description);
        setTags(resData?.data?.data?.tags?.split(","));
        setSelectedImage(resData?.data?.data?.image);
        setIsEdit(true);
      }
    }
  };

  //  handleAddSeoTag
  const addSeoTag = (e) => {
    if (tag === "") {
      return;
    }

    setTags((p) => [...p, tag]);
    setTag("");
  };

  //   removeTagHandler
  const removeTagHandler = (tag) => {
    const tagsCopy = [...tags];
    const tagIndex = tagsCopy.findIndex((e) => e === tag);
    tagsCopy.splice(tagIndex, 1);
    setTags(tagsCopy);
  };

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage(event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  //   handleSubmit
  const handleSubmit = async () => {
    const params = {
      title,
      description,
      tags: tags.join(),
      image: selectedImageFile !== null ? selectedImageFile : selectedImage,
      pageName: newpageDetails?.pageName,
    };
    console.log({ params });

    for (let key in params) {
      if (params[key] === "" || params[key] === null) {
        return toast.error("All fields are required");
      }
    }

    const data = new FormData();
    data.append("title", params.title);
    data.append("description", params.description);
    data.append("tags", params.tags);
    data.append("image", params.image);
    data.append("pageName", params.pageName);

    const submitApiURL = `${getUrl}?isEdit=${isEdit}&pageName=${newpageDetails?.pageName}`;
    const Header = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    setSubmitLoading(true);
    const resData = await Post(submitApiURL, data, Header);
    setSubmitLoading(false);
    if (resData !== undefined) {
      console.log(resData.data);
      toast.success(
        `${params.pageName} Page Seo Data has been updated SuccessFully`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>{newpageDetails?.pageName} Page SEO Detail</h1>
      </div>

      <div className="m-3 col-md-6 col-xm-12">
        {/* titlee */}
        <div class="form-group">
          <label for="exampleInputEmail1">Page Title</label>
          <input
            type="text"
            class="form-control"
            type="text"
            placeholder="Add Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        {/* Description */}
        <div class="form-group">
          <label for="exampleInputEmail1">Page Description</label>
          <textarea
            type="text"
            class="form-control"
            type="text"
            placeholder="Add Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        {/* tags */}
        <div class="form-group">
          <label for="exampleInputEmail1">Page Tags</label>
          <input
            type="text"
            class="form-control"
            type="text"
            placeholder="Add Tags"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            onKeyDown={(e) =>
              (e.code === "Enter" || e.code === "NumpadEnter") && addSeoTag()
            }
          />
        </div>

        {/* Map tags */}
        <div className="seo__tags">
          {tags.map((tag) => (
            <Tag tag={tag} onPress={removeTagHandler} />
          ))}
        </div>

        {/* Image input */}
        <div class="form-group" className="seo__imagePicker">
          <input
            type="file"
            name="uploadfile"
            id="img"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event);
            }}
          />
          <label for="exampleInputEmail1">Select Image</label>
          <label
            for="img"
            className="pagination-button btn-lg btn-block text-center"
            style={{ backgroundColor: "#044d81 !important" }}
            onChange={(e) => {
              console.log(e.target.files[0]);
            }}
          >
            {selectedImage ? "Change selection" : "Pick an Image"}
          </label>
        </div>

        {/* Shoe Image */}
        {selectedImage && (
          <div>
            <img
              alt="not fount"
              src={
                selectedImageFile == null
                  ? `${imageUrl}${selectedImage}`
                  : selectedImage
              }
              className="seo__image"
            />
          </div>
        )}

        {/* Submit */}
        <Button
          disabled={submitLoading}
          className="pagination-button btn-lg text-center"
          style={{ backgroundColor: "#044d81 !important" }}
          onClick={handleSubmit}
        >
          <i>✔ </i> Submit
        </Button>
      </div>
    </section>
  );
};

export default Seo;
