import "../assets/Styles/forgot.css";
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #DA06E4",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function ForgetPasswordModal({
  open,
  setOpen,
  children,
  type,
  isSpecial,
}) {
  const handleClose = () => {
    isSpecial && isSpecial(null);
    setOpen(false);
  };

  return (
    <div className={`${type == "big" && "big_modal_new"}`}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>{children}</Box>
        </Fade>
      </Modal>
    </div>
  );
}
