import React, { useEffect, useState } from "react";
import ModalSkeleton from "./ModalSkeleton";
import GroupWorkIcon from "@mui/icons-material/GroupWork";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { CardContent, CardMedia } from "@mui/material";
import { imageUrl } from "../Config/apiUrl";
import { green } from "@mui/material/colors";
import { toast } from "react-toastify";

const AddAndEditFaqModal = ({
  open,
  setOpen,
  data,
  onUpdate,
  isLoading,
  faqModalKey,
  setData
}) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [status, setStatus] = useState("pending");

  const [modalKey, setModalKey] = useState(null);

  //   photo states
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  useEffect(() => {
    if (faqModalKey == "update") {
      setAnswer(data?.answer);
      setQuestion(data?.question);
      setStatus(data?.status);
      setSelectedImage(data?.photo)
    }
  }, [faqModalKey, data]);

  // radio
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    console.log(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage(event.target.result);
      console.log("event.target.result: ", event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen} isSpecial={setData}>
      <form class="login100-form validate-form">
        <span class="login100-form-title">
          {modalKey == "add" ? "Add Question" : "Update Question"}
        </span>

        {/* question */}
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Question</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Input Question Here"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* question */}
        <div
          style={{ marginTop: "10px" }}
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Answer</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Input Answer Here"
            value={answer}
            onChange={(e) => {
              setAnswer(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* Image */}
        <div class="form-group" className="seo__imagePicker">
          <input
            type="file"
            name="uploadfile"
            id="image"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event);
            }}
          />
          <label
            for="exampleInputEmail1"
            className="label-input100"
            style={{ marginTop: "10px" }}
          >
            Select Image
          </label>
          <label
            for="image"
            className="btn btn-primary btn-lg btn-block"
            style={{
              backgroundColor: green[500],
            }}
            onChange={(e) => {
              console.log(e.target.files[0]);
            }}
          >
            {selectedImage ? "Change selection" : "Pick an Image"}
          </label>
        </div>

        {/* Shoe Image */}
        {selectedImage && (
          <div>
            <img
              src={
                selectedImageFile == null
                  ? `${imageUrl}${selectedImage}`
                  : selectedImage
              }
              className="faq-modal-image"
            />
          </div>
        )}

        {/* radio */}
        {faqModalKey == "update" && (
          <FormControl component="fieldset" style={{ marginTop: "10px" }}>
            <RadioGroup
              row
              aria-label="status"
              name="controlled-radio-buttons-group"
              value={status}
              onChange={handleChange}
            >
              <FormControlLabel
                disabled
                value="pending"
                control={<Radio />}
                label="Pending"
              />
              <FormControlLabel
                value="rejected"
                control={<Radio />}
                label="Reject"
              />
              <FormControlLabel
                value="approved"
                control={<Radio />}
                label="Approve"
              />
            </RadioGroup>
          </FormControl>
        )}

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="login100-form-btn"
              onClick={async (e) => {
                e.preventDefault();
                if (question == "") {
                  return toast.error("Please input question");
                }
                if (answer == "") {
                  return toast.error("Please input answer");
                }

                // create form data
                const formData = new FormData();
                formData.append("question", question);
                formData.append("answer", answer);
                selectedImageFile !== null &&
                  formData.append("photo", selectedImageFile);

                if (modalKey == "add") {
                  formData.append("status", "approved");
                } else {
                  formData.append("status", status);
                }

                modalKey == "add"
                  ? await onUpdate(formData)
                  : await onUpdate(formData, data?._id);
                setQuestion("");
                setAnswer("");
                setSelectedImage(null);
                setSelectedImageFile(null);
              }}
            >
              {isLoading ? "Adding..." : "Add"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditFaqModal;
