import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apiUrl } from "../Config/apiUrl";

const AddAndEditCmsWebItemModal = ({
  title,
  type,
  visibleModal,
  itemTitle,
  setItemTitle,
  itemDescription,
  setItemDescription,
  icon,
  iconDisplay,
  onPress,
  onPressCancel,
  itemLoading,
  uploadImages,
}) => {
  return (
    <Modal
      className="modal-dialog-centered custom-addLang-modal"
      isOpen={visibleModal}
      toggle={onPressCancel}
    >
      <div className="modal-Header">
        <h2 className="text-center">{title}</h2>
      </div>
      <div className="addlang-modalBody">
        {itemTitle !== undefined && (
          <div className="innerContainer">
            <div className="form-group">
              <input
                type="text"
                placeholder="Add Title"
                value={itemTitle}
                onChange={(e) => setItemTitle(e.target.value)}
              />
            </div>
          </div>
        )}
        {itemDescription !== undefined && (
          <div className="innerContainer">
            <div className="form-group">
              <textarea
                type="text"
                placeholder="Add Description"
                value={itemDescription}
                onChange={(e) => setItemDescription(e.target.value)}
              />
            </div>
          </div>
        )}
        {icon !== undefined && (
          <div className="innerContainer">
            <div className="form-group">
              <div className="icon-upload-avatar">
                {icon == "" ? (
                  <>
                    <span>
                      <i className="fa fa-upload" style={{ fontSize: 50 }} />
                      <p>Upload Photo</p>
                    </span>
                  </>
                ) : iconDisplay !== null ? (
                  <img
                    src={iconDisplay}
                    alt="User Image"
                    className="image-fluid"
                  />
                ) : (
                  <img
                    src={`${apiUrl}/${icon}`}
                    alt="User Image"
                    className="image-fluid"
                  />
                )}
                <input
                  type="file"
                  id="InputCoverImage"
                  accept="image/png, image/jpeg"
                  onChange={(e) => uploadImages(e)}
                />
              </div>

              {/* <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => uploadImages(e)}
            /> */}
            </div>
          </div>
        )}
        <div className="modal-footer footer-div">
          <div>
            <Button
              className="btn btn-user btn-block btn-cancel"
              onClick={onPressCancel}
            >
              Cancel
            </Button>
          </div>

          <div>
            <Button
              className="btn btn-user btn-block btn-save"
              onClick={onPress}
              disabled={itemLoading}
            >
              {itemLoading ? "Loading.." : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAndEditCmsWebItemModal;
