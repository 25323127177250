import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import {
  apiHeader,
  apiUrl,
  fallBackIcon,
  imageUrl,
  URL,
} from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import EditSkillModal from "../components/AddAndEditCategoryModal";
import AddSectionModal from "../components/AddSectionModal";
// Mui Button
import MuiButton from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddAndEditFaqModal from "./../components/AddAndEditFaqModal";
import { toast } from "react-toastify";
import NullDataComponent from "../components/NullDataComponent";
import AddAndEditOurMotoModal from "../components/AddAndEditOurMotoModal";
import AddAndEditVoucherModal from "../components/AddAndEditVoucherModal";
import AddAndEditTestimonials from "../components/AddAndEditTestimonials";

const Testimonials = () => {
  const token = useSelector((state) => state.authReducer.token);
  const router = useHistory();
  const [limit, setLimit] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxLength, setMaxLength] = useState(0);
  const [faqs, setFaqs] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCategory, setEditedCategory] = useState("");

  //   faq modals
  const [isFaqModalOpen, setIsFaqModalOpen] = useState(false);
  const [faqModalKey, setFaqModalKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [faqModalData, setFaqModalData] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState(null);
console.log(faqModalData)
  // reported ads listing method
  const getAllFaqs = async () => {
    setIsGetting(true);
    const url = URL(`/testimonial/admin/all`);

    const responseData = await Get(url, token);
    if (responseData !== undefined) {
      setFaqs(responseData?.data?.data);
    }
    setIsGetting(false);
  };

  useEffect(() => {
    getAllFaqs();
  }, []);

  console.log({ faqModalData, faqModalKey });
  //   handleFaqUpdate
  const handleFaqUpdate = async (params, id) => {
    const url =
      faqModalKey == "add"
        ? URL(`/testimonial/ `)
        : URL(`/testimonial/${faqModalData?._id}`);

    setIsLoading(true);
    const response =
      faqModalKey == "add"
        ? await Post(url, params, apiHeader(token))
        : await Patch(url, params, apiHeader(token));
    setIsLoading(false);

    if (response != undefined) {
      setFaqModalData(null);
      setIsFaqModalOpen(false);
      toast.success(
        faqModalKey == "add"
          ? "Voucher added successfully."
          : "Voucher edited successfully."
      );
      getAllFaqs();
    }
  };

  //  handleItemDelete
  const handleItemDelete = async () => {
      console.log(idToBeDeleted)
    const url = URL(`/testimonial/${idToBeDeleted}`);

    setIsLoading(true);
    const response = await Delete(url, null, apiHeader(token));
    setIsLoading(false);

    if (response !== undefined) {
      setIsOpenDeleteModal(false);
      toast.success("Voucher deleted successfully.");
      getAllFaqs();
    }
  };

  return (
    <section className="container-fluid reported-ads">
      <div className="fieldSetHeaderContainer d-flex flex-column">
        <h1>Testimonials</h1>
      </div>

      {/* Add Section Button Container */}
      <div className="place-add-btn-container">
        <MuiButton
          onClick={() => {
            setFaqModalKey("add");
            setFaqModalData(null);
            setIsFaqModalOpen(true);
          }}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Item
        </MuiButton>
      </div>

      <div className="fieldSetMainContainer">
        {isGetting ? (
          <TableRowsLoader />
        ) : (
          <div className="col-md-12 table_recent">
            <div className="table-responsive">
              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Name</th>
                    <th>Rating</th>
                    <th>Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {faqs?.map((item, index) => {
                      console.log(item)
                    return (
                      <>
                        <tr key={item?._id}>
                          <td>{index + 1}</td>

                          <td>{item?.userName}</td>
                          <td>{item?.rating}</td>
                          <td>{item?.description}</td>

                        
                          <td>
                            <div className="d-flex">
                              <li
                                className="page-item align-self-end"
                                onClick={() => {
                                  setFaqModalData(item);
                                  setFaqModalKey("update");
                                  setIsFaqModalOpen(true);
                                }}
                              >
                                <Button className="pagination-button">
                                  Edit
                                </Button>
                              </li>

                              <li
                                className="page-item align-self-end ml-5"
                                onClick={() => {
                                  setIdToBeDeleted(item?._id);
                                  console.log(item?._id)
                                  setIsOpenDeleteModal(true);
                                }}
                              >
                                <Button className="pagination-button">
                                  Delete
                                </Button>
                              </li>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>

              {faqs.length == 0 && (
                <NullDataComponent text="No Voucher found." />
              )}
            </div>
          </div>
        )}
      </div>

      {/* to delete */}
      <AreYouSureModal
        open={isOpenDeleteModal}
        setOpen={setIsOpenDeleteModal}
        label={"Are You Sure?"}
        message="Do you really want to delete this Voucher?"
        handleYes={handleItemDelete}
        isLoading={isLoading}
      />

      {/* AddAndEditVoucherModal */}
      <AddAndEditTestimonials
        open={isFaqModalOpen}
        setOpen={setIsFaqModalOpen}
        data={faqModalData}
        setData={setFaqModalData}
        faqModalKey={faqModalKey}
        isLoading={isLoading}
        onUpdate={handleFaqUpdate}
      />
    </section>
  );
};

export default Testimonials;
