import React, { useEffect, useState } from "react";
import ModalSkeleton from "./ModalSkeleton";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { imageUrl } from "../Config/apiUrl";
import { green } from "@mui/material/colors";
import { toast } from "react-toastify";

// Radio imports
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";

const AddAndEditCategoryModal = ({
  open,
  setOpen,
  _skill,
  onUpdate,
  isLoading,
}) => {
  const [skill, setSkill] = useState(_skill);
  const [position, setPosition] = useState(_skill);
  //   photo states
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  // radio
  // const [value, setValue] = useState("active");
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  useEffect(() => {
    setSkill(_skill?.sectionName);
    setPosition(_skill?.position);
    setSelectedImage(`${imageUrl}${_skill?.photo}`);

    console.log(_skill);
    // setValue(_skill?.active);
  }, [_skill]);

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    console.log(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage(event.target.result);
      console.log("event.target.result: ", event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form class="login100-form validate-form">
        <span class="login100-form-title">Update Section</span>

        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Section</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Edit Section"
            value={skill}
            onChange={(e) => {
              setSkill(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>
        <div
          class="wrap-input100 validate-input"
          data-validate="Username is reauired"
        >
          <span class="label-input100">Position</span>
          <input
            class="input100"
            type="text"
            name="text"
            placeholder="Edit Position"
            value={position}
            onChange={(e) => {
              setPosition(e.target.value);
            }}
          />
          <span class="focus-input100">
            <GroupWorkIcon />
          </span>
        </div>

        {/* Image */}
        <div class="form-group" className="seo__imagePicker">
          <input
            type="file"
            name="uploadfile"
            id="image-22"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event);
            }}
          />
          <label
            for="exampleInputEmail1"
            className="label-input100"
            style={{ marginTop: "10px" }}
          >
            Select Image
          </label>
          <label
            for="image-22"
            className="btn btn-primary btn-lg btn-block"
            style={{
              backgroundColor: green[500],
            }}
            onChange={(e) => {
              console.log(e.target.files[0]);
            }}
          >
            {selectedImage ? "Change selection" : "Pick an Image"}
          </label>
        </div>

        {/* Shoe Image */}
        {selectedImage && (
          <div>
            <img
              src={selectedImageFile == null ? selectedImage : selectedImage}
              className="faq-modal-image"
            />
          </div>
        )}

        {/* radio */}
        {/* <FormControl component="fieldset" style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="active"
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value="inActive"
              control={<Radio />}
              label="In-Active"
            />
          </RadioGroup>
        </FormControl> */}

        {/* btn */}
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              class="login100-form-btn"
              onClick={(e) => {
                e.preventDefault();
                if (!skill) {
                  return toast.warn("Please enter section name.");
                }

                // onUpdate(_skill?._id, skill, value == "active" ? true : false);
                onUpdate(
                  _skill?._id,
                  skill,
                  position,
                  selectedImageFile == null ? _skill?.photo : selectedImageFile
                );
              }}
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditCategoryModal;
