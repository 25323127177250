import {
  CMS_PAGE_UPDATE,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  NAV_CHAPTER,
  NAV_SECTION,
  SET_SELECTED_PAGE,
  NAV_VIDEO,
} from "./actionType";

export function saveLoginUserData(response) {
  return { type: LOGIN_SUCCESS, payload: response };
}

export function signOutRequest() {
  return {
    type: LOGOUT_REQUEST,
  };
}

// *********************************************************************************************************

export function navSection(id) {
  return {
    type: NAV_SECTION,
    payload: id,
  };
}

export function navChapter(id) {
  return {
    type: NAV_CHAPTER,
    payload: id,
  };
}
export function navVideo(id) {
  return {
    type: NAV_VIDEO,
    payload: id,
  };
}

export function cmsPageUpdate(data) {
  console.log("data: ", data);
  return {
    type: CMS_PAGE_UPDATE,
    payload: data,
  };
}

export function setSelectedPage(data) {
  return { type: SET_SELECTED_PAGE, data };
}
